import axios from 'axios';
import config from '../../config.json';

const apiMapping = {
  GET: axios.get,
  POST: axios.post,
  PUT: axios.put
};
interface IUserInfoType {
  companyId: string | any,
  token: string,
  refreshToken: string | null,
  userName: string | null,
  apiKey: string,
  sessionId: string,
  errorMessage:string,  
  isSessionExpired:|true|false,
  isAuthorized:|true|false,
  locale:string,
  language:string,
  timeZone:{},
  firstPath:string,
  isLoaded:boolean,
  queryParams:string
}
interface IAppData {
  serviceAdministratorNumber: string,
  accountNumber: string,
  serviceJobNumber: string,
  technicianName?: string,
  clientId:string,
  serviceAdministratorId:string,
  serviceProviderId:string,
  serviceProviderName:string,
  path:string

}
export const userInfo: IUserInfoType = {
  companyId: '',
  token: '',
  refreshToken: '',
  userName: 'admin',
  apiKey: '',
  sessionId: '',
  isSessionExpired:false,
  isAuthorized:false,
  errorMessage:'',
  locale:'',
  language:'',
  timeZone:{},
  firstPath:'' ,
  isLoaded:false,
  queryParams:null
};
export const AppData: IAppData = {
  serviceAdministratorNumber: '',
  accountNumber: '',
  serviceJobNumber: '',
  technicianName: '',
  clientId:'',
  serviceAdministratorId:'',
  serviceProviderId:'',
  serviceProviderName:'',
  path:''
};
export const SUCCESS='Success';
export const FAILURE='Failure';

const getHeaders = (customHeaders?: Object) => {
  const commonHeaders = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
    "x-api-key":config.X_API_KEY,
    Accept: 'application/json',
    Authorization: `Bearer ${userInfo.token}`,
    companyId: userInfo.companyId,
    correlationId: Date.now(),
    sender: 'web-tech-setup',
    sessionId: userInfo.sessionId,
    username: userInfo.userName,
  };
  return { ...commonHeaders, ...customHeaders };
};
const executeApi = (resolve, reject) => (path, bodyContent, headers) => {
  const { method = 'GET', body = null } = bodyContent;
  let  endpoint=config.API_BASE_PATH;
  const url=`${endpoint}${path}`
  const arrayOfData = [url, body, headers].filter(item => item !== null);  
  apiMapping[method]
    .apply(axios, arrayOfData)
    .then(res => manageResponse(resolve, reject, res))
    .catch(err => {
      const errorToReturn = manageError(err);
      if (errorToReturn &&errorToReturn.Error  && errorToReturn.Error.Code === '401') {
        resolve(errorToReturn);
      } else {
        reject(errorToReturn);
      }
    });
};

const manageResponse = (resolve, reject, res) =>
  res.status === 200 ? resolve(res.data) : reject('Error occurred while getting the response');

const manageError = payload => {
  if (payload.response && payload.response.status !== 200) {
    if (payload.response.status === 403 && payload.response.data && payload.response.data.errors) {
      const { description } = payload.response.data.errors[0];
      return buildErrorMessage(401, description);
    }
    else if (payload.response.status === 401 && payload.response.data && payload.response.data.message) {
      const { message } = payload.response.data;
      return buildErrorMessage(401, message);
    }
    else if (payload.response.status === 400 && payload.response.data && payload.response.data.errors) {
      const { description } = payload.response.data.errors[0];
      return buildErrorMessage(401, description);
    }
    else if (payload.response.status !== 400 && payload.response.statusText !== '') {
      return buildErrorMessage(payload.response.status, payload.response.statusText);
    }
  } else
    return buildErrorMessage('Server Issue', 'Server Issue : Please contact system administrator');
};

const buildErrorMessage = (code, message) => ({
  Error: {
    Code: `${code}`,
    Message: message
  }
});

// export const Get = ( path, headers) => {
//   return new Promise((resolve, reject) => {
//     const custom_headers = {
//       headers: headers ? headers : getHeaders(headers),
//       data: {} 
//     };
//     executeApi(resolve, reject)( path, {}, custom_headers);
//   });
// };
export const Get = (path, headers,urlParams) => {
  return new Promise((resolve, reject) => {
    const custom_headers = {
      headers: getHeaders(headers),
      data: {} // Needed for passing Content-Type in headers
    };
    executeApi(resolve, reject)(path,{},custom_headers);
  });
};

export const Post = ( path,params, data) => {
  return new Promise((resolve, reject) => {
    const content = {
      body: JSON.stringify({ ...data }),
      method: 'POST'
    };
    const custom_headers = {
      headers: getHeaders(params)
    };
    executeApi(resolve, reject)( path, content, custom_headers);
  });
};
export const Put = (path,params, data) => {
  return new Promise((resolve, reject) => {
    const content = {
      body: JSON.stringify({ ...data }),
      method: 'PUT'
    };
    const custom_headers = {
      headers: getHeaders(params)
    };
    executeApi(resolve, reject)(path,content, custom_headers);
  });
};