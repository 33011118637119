import React, { Component } from "react";
import { Post, AppData, userInfo } from '../../../modules/apis/apiUtitlity';
import { loadCodeDescription, searchCertificateName, insertUpdateSPCertifications, loadSPCertifications, deleteSPCertifications } from '../../../constants/apiConst';
import Select from "../../../modules/components/Select";
import AutoCompleteInput from "../../../modules/components/AutoCompleteInput";
// import "./Certification.module.css";
import classes from "./Certification.module.scss";
import * as certTypes from './certTypes';
import Input from "../../../modules/components/Input";
import DatePickerMedium from "../../../modules/components/DatePickerMedium";
import ButtonMedium from "../../../modules/components/ButtonMedium";
import CertificationsTable from "./CertificationsTable";
import Label from "../../../modules/components/Label";
import { withTranslation } from 'react-i18next'
import captions from './captionsSpCertifications.json'

const config = {
    maxLimt: 10,
    minInput: 3,
    entityType:'com.servicebench.cribbage.model.common.ServiceProviderCert',
};
interface IProps {
    t: Function,
    history: {
        push: Function
    }
}
class Certification extends Component<IProps, certTypes.IState> {
    constructor(props) {
        super(props);
        this.state = {
            isCheckExpiry: 0,
            selectedCertificationTypeLabel: '',
            selectedCertificationTypeValue: '',
            certificationTypes: null,
            certificationsList: null,
            certificationNameInputReadOnly: true,
            selectedCertificationName: "",
            selectedReferenceNumber: "",
            selectedExpiryDate: null,
            selectedCertificationId: '',
            active: 1,
            isEditCalled: false,
            isActionsDisabled: false,
            isForceEmpty: false,
            errors: { name: '', type: this.props.t(captions["err-sel-type"].id, captions["err-sel-type"].default), date: '', noData: '' },
            certNameEntered: '',
            isMaxSelected: false
        };
        this.onCertificationTypeSelectChange = this.onCertificationTypeSelectChange.bind(this);
        this.captureCertNameSelect = this.captureCertNameSelect.bind(this);
        this.onCertificationTypeSelectChange = this.onCertificationTypeSelectChange.bind(this);
        this.convertObjectToGenericType = this.convertObjectToGenericType.bind(this);
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.expiryDateChangeHanlder = this.expiryDateChangeHanlder.bind(this);
        this.onClickAddHandler = this.onClickAddHandler.bind(this);
        this.onCertificationNameChange = this.onCertificationNameChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.onClickEditHandler = this.onClickEditHandler.bind(this);
        this.onClickSaveHandler = this.onClickSaveHandler.bind(this);
        this.filterSelectedCombination = this.filterSelectedCombination.bind(this);
        this.insertUpdateCertifications = this.insertUpdateCertifications.bind(this);
        document.title = this.props.t(captions["lbl-title"].id, captions["lbl-title"].default);
    }
    convertObjectToGenericType(object: any, labelKey: string, valueKey: string, inputType: string) {
        if (object && object.length > 0) {
            let newObj: any = [];
            object.forEach(function (item: any, index) {
                let newItem = {
                    label: item[labelKey],
                    value: item[valueKey],
                    data: item
                };
                newObj.push(newItem);
            });
            return newObj;
        }
        else { return {} }
    }
    filterSelectedCombination(availableSelections: certTypes.TCertifications, apiResponse: certTypes.TCertifications) {
        let filteredOptions = [];
        if (apiResponse && apiResponse.length > 0 && Array.isArray(availableSelections) && availableSelections.length>0) {
            availableSelections.forEach(function (item: certTypes.TCertification, index) {
                let keySelItem = item.certificationTypeCode ? `${item.certificationTypeCode}-${item.certificationId}` : `${item.certificationId}`;
                apiResponse = apiResponse.filter(function (responseItem) {
                    let keyRespItem = responseItem.certificationTypeCode ? `${responseItem.certificationTypeCode}-${responseItem.certificationId}` : `${item.certificationId}`;
                    return keyRespItem !== keySelItem;
                });
            });
            return apiResponse;
        }
        else {
            filteredOptions = [...apiResponse];
        }

        return filteredOptions;
    }
    async onCertificationNameChange(certNameEntered: string, cb: Function) {
        let { errors } = this.state;
        let certNameList = [];
        if (certNameEntered) {
            errors.name = '';
            this.setState({
                certNameEntered: certNameEntered,
                selectedCertificationName: '',
                errors: errors
            });
            if (certNameEntered.length >= config.minInput) {
                let response: certTypes.TResponseDataType = await Post(searchCertificateName.subUri, {}, {
                    "serviceAdministratorNumber": AppData.serviceAdministratorNumber
                    , certificationName: certNameEntered
                    , certificationTypeCode: this.state.selectedCertificationTypeValue
                    , entityType : 'SP'
                });
                if (response && response.Result && response.Result === 'Success') {
                    certNameList=response.certificationList;
                    if (certNameList && certNameList.length > 0) {
                        certNameList = this.filterSelectedCombination(this.state.certificationsList, certNameList);
                        if (certNameList && certNameList.length === 0) {
                            cb(null);
                            errors.name = this.props.t(captions["err-no-options-found"].id, captions["err-no-options-found"].default);
                            this.setState({
                                errors
                            });
                        }
                        else if (certNameList && certNameList.length > 0) {
                            errors.name = '';
                            certNameList = this.convertObjectToGenericType(certNameList, 'certificationName', 'certificationId', null);
                            cb(certNameList);
                        }
                    }
                    else {
                        cb(null);
                        errors.name = this.props.t(captions["err-no-options-found"].id, captions["err-no-options-found"].default);
                        this.setState({
                            errors
                        });
                    }
                }
            }
        }
        else {
            errors.name = this.props.t(captions["err-req-name"].id, captions["err-req-name"].default);
            errors.date = '';
            this.setState({
                certificationNameInputReadOnly: false,
                certNameEntered: '',
                selectedCertificationName: '',
                isCheckExpiry: '',
                errors: errors
            });
        }

    }
    captureCertNameSelect(inputCode, selectedOption) {
        let { errors, isCheckExpiry } = this.state;
        if (selectedOption && selectedOption.value) {
            errors.name = '';
            if (selectedOption.data) {
                isCheckExpiry = selectedOption.data.checkExpiration
            }
        }
        else {
            errors.name = this.props.t(captions["err-req-name"]);
        }
        this.setState({
            certificationNameInputReadOnly: false,
            selectedCertificationName: selectedOption.label,
            certNameEntered: selectedOption.label,
            selectedCertificationId: selectedOption.value,
            errors,
            isCheckExpiry
        });
    }
    onCertificationTypeSelectChange(e) {
        let index = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[index].text;
        let value = e.target.value;
        let { errors, selectedCertificationTypeValue, selectedCertificationTypeLabel, certificationNameInputReadOnly
        } = this.state;
        if (value && value !== "") {
            errors.type = '';
            selectedCertificationTypeValue = value;
            selectedCertificationTypeLabel = label;
            certificationNameInputReadOnly = false;
        }
        else {
            errors.type = this.props.t(captions["err-req-type"].id, captions["err-req-type"].default)
            selectedCertificationTypeValue = '';
            selectedCertificationTypeLabel = '';
            certificationNameInputReadOnly = true;
        }
        errors.name = '';
        errors.date = '';
        this.setState({
            selectedCertificationTypeValue,
            selectedCertificationTypeLabel,
            certificationNameInputReadOnly,
            selectedCertificationName: '',
            selectedCertificationId: '',
            selectedReferenceNumber: '',
            errors,
            certNameEntered: '',
            isCheckExpiry: 0
        });
    }

    inputChangeHandler(value, objectId) {
        if (!objectId) {
            let { errors } = this.state;
            this.setState({
                selectedReferenceNumber: value ? value : '',
                errors: errors
            });
        }
        else if (objectId) {
            let certList: certTypes.TCertifications = this.state.certificationsList;
            if (certList && certList.length > 0) {
                certList.forEach((item: certTypes.TCertification, index: number) => {
                    let tempId = item.certificationTypeCode ? `${item.certificationTypeCode}-${item.certificationId}` : `${item.certificationId}`;
                    if (item && item.certificationId && objectId === tempId) {
                        item.certificationNumber = value ? value : '';
                    }
                })
            }
            this.setState({ certificationsList: certList });
        }
    }
    expiryDateChangeHanlder(date: Date, id) {
        let { errors, isCheckExpiry } = this.state;
        if (id && id !== undefined) {
            let certList = this.state.certificationsList;
            if (certList && certList.length > 0) {
                certList.forEach((item: certTypes.TCertification, index: number) => {
                    let objectId = item.certificationTypeCode ? `${item.certificationTypeCode}-${item.certificationId}` : `${item.certificationId}`;
                    if (item && item.certificationId && id === objectId) {
                        item.expirationDate = date ? date.toISOString() : '';
                    }
                })
            }
            this.setState({ certificationsList: certList });
        }
        else if (date) {
            errors.date = '';
            this.setState({ selectedExpiryDate: date, errors: errors });
        }
        else if (!date) {
            if ((isCheckExpiry === 1 || '1' === isCheckExpiry) && this.state.selectedCertificationId) {
                errors.date = this.props.t(captions["err-req-exp-date"].id, captions["err-req-exp-date"].default);
                this.setState({ selectedExpiryDate: date, errors: errors });
            }
            else {
                this.setState({ selectedExpiryDate: null, errors: errors });
            }
        }
    }
    async insertUpdateCertifications(payLoad: certTypes.TCertificationInsertType | certTypes.TCertificationInsertTypes) {
        let data = null;
        let certList;
        if (payLoad && Array.isArray(payLoad)) {
            certList = payLoad;
        }
        else {
            certList = [payLoad];
        }
        data = { accountNumber: AppData.accountNumber, serviceAdministratorNumber: AppData.serviceAdministratorNumber, certifications: certList };
        // console.log('data' ,data);
        
        let response: certTypes.TResponseDataType = await Post(insertUpdateSPCertifications.subUri, null, data);
        if (response)
            if (response.Result && response.Result === 'Error') {
                console.log('error while saving certifications')
            }
    }
    onClickAddHandler() {
        let allInputAvailable = true;
        let { errors } = this.state ;
        if (!this.state.selectedCertificationTypeValue || this.state.selectedCertificationTypeValue === '') {
            allInputAvailable = false;
            errors.type = this.props.t(captions["err-req-type"].id, captions["err-req-type"].default);
        }
        else {
            if (!this.state.selectedCertificationName || this.state.selectedCertificationName === '') {
                allInputAvailable = false;
                errors.name = this.props.t(captions["err-req-name"].id, captions["err-req-name"].default);
            }
            if (this.state.isCheckExpiry && (this.state.isCheckExpiry === 1 || '1' === this.state.isCheckExpiry||this.state.isCheckExpiry===true||this.state.isCheckExpiry==='true') && (!this.state.selectedExpiryDate || this.state.selectedExpiryDate === undefined)) {
                allInputAvailable = false;
                errors.date = this.props.t(captions["err-req-exp-date"].id, captions["err-req-exp-date"].default);
            }
        }
        if (allInputAvailable) {
            let correctedDate = null
            if (this.state.selectedExpiryDate) {
                correctedDate = this.state.selectedExpiryDate.toISOString();
            }
            let newObj: any | certTypes.TCertification = {
                expirationDate: correctedDate ? correctedDate : '',
                certificationNumber: this.state.selectedReferenceNumber,
                certificationName: this.state.selectedCertificationName,
                certificationId: this.state.selectedCertificationId
            };
            // console.log('new obj' ,newObj);
            this.insertUpdateCertifications(newObj);
            //below properties are not required for api call and would cause failure if sent 
            newObj.certificationType = this.state.selectedCertificationTypeLabel;
            newObj.certificationTypeCode = this.state.selectedCertificationTypeValue;
            newObj.checkExpiration=this.state.isCheckExpiry;
            let updatedCertData: certTypes.TCertifications = [];
            let { isMaxSelected, isActionsDisabled } = this.state;
            if (this.state.certificationsList) { updatedCertData = [newObj, ...this.state.certificationsList]; }
            else {
                updatedCertData = [newObj];
            }
            errors.type = this.props.t(captions["err-sel-type"].id, captions["err-sel-type"].default);
            if (updatedCertData && updatedCertData.length >= config.maxLimt) {
                isMaxSelected = true;
                errors.type = '';
                isActionsDisabled = true;
            }
            errors.name = '';
            errors.date = '';
            this.setState({
                certificationsList: updatedCertData,
                selectedCertificationTypeValue: '',
                selectedCertificationTypeLabel: '',
                selectedExpiryDate: undefined,
                selectedReferenceNumber: '',
                selectedCertificationName: '',
                isForceEmpty: true,
                certificationNameInputReadOnly: true,
                certNameEntered: '',
                isMaxSelected,
                isActionsDisabled
            })
        } else {
            this.setState({ errors: errors });
        }
    }
    onClickEditHandler(event) {
        this.setState({ isEditCalled: true, isActionsDisabled: true });
    }

    onClickSaveHandler(event, object) {
        let { isMaxSelected,isEditCalled, certificationsList, errors, isActionsDisabled, selectedCertificationTypeValue } = this.state;
        isActionsDisabled = false;
        let isValidationFailed=false;
        if (certificationsList) {
            if (certificationsList.length > 0) {
                let payLoad = [];
                certificationsList.forEach((item: certTypes.TCertification, index) => {
                    if(!item.expirationDate && item.checkExpiration &&(item.checkExpiration===true||item.checkExpiration==='true'||item.checkExpiration==='1' ||item.checkExpiration===1))
                    {
                        isValidationFailed=true;
                        isEditCalled=true;
                    }
                    let obj = { expirationDate: item.expirationDate ? item.expirationDate : '', certificationNumber: item.certificationNumber, certificationName: item.certificationName, certificationId: item.certificationId }
                    payLoad.push(obj);
                })
            if(!isValidationFailed)
            {
                isEditCalled=false;
                this.insertUpdateCertifications(payLoad);
                if (certificationsList.length >= config.maxLimt) {
                    isMaxSelected = true;
                    errors.type = '';
                    isActionsDisabled = true;
                }
                else {
                    errors.type = selectedCertificationTypeValue ? '' : this.props.t(captions["err-sel-type"].id, captions["err-sel-type"].default);
                    isMaxSelected = false;
                }
            }
            }
        }
        this.setState({ isEditCalled, isActionsDisabled, isMaxSelected, errors });
    }

    async handleDelete(deletedItem: certTypes.TCertification) {
        if (deletedItem) {
            let avlData: certTypes.TCertifications = null;
            let targetCombinedKey = deletedItem.certificationTypeCode ? `${deletedItem.certificationTypeCode}-${deletedItem.certificationId}` : `${deletedItem.certificationId}`;
            avlData = this.state.certificationsList.filter((item, index) => {
                let itemCombinedKey = item.certificationTypeCode ? `${item.certificationTypeCode}-${item.certificationId}` : `${item.certificationId}`;
                return targetCombinedKey !== itemCombinedKey
            });
            let payload = { "serviceAdministratorNumber": `${AppData.serviceAdministratorNumber}`, "accountNumber": `${AppData.accountNumber}`, "certificationId": `${deletedItem.certificationId}` }
            let response: certTypes.TResponseDataType = await Post(deleteSPCertifications.subUri, {}, { ...payload });
            let { isEditCalled, isActionsDisabled, errors } = this.state;
            if (!avlData || (avlData && avlData.length === 0)) {
                isActionsDisabled = false;
                isEditCalled = false;
                errors.noData = this.props.t(captions["lbl-no-cert-added"].id, captions["lbl-no-cert-added"].default)
            }
            this.setState({ certificationsList: avlData, isActionsDisabled, isEditCalled, errors });
            if (response && response.Result && response.Result === 'Failure') {
                throw new Error('delete failed from handleDelete method from Certifications ');
            }
        }
    }
    async componentDidMount() {
        let responseCertType: certTypes.TResponseDataType = await Post(loadCodeDescription.subUri, {}, {
            "codeType": 990033,
            "serviceAdministratorId": AppData.serviceAdministratorId,
            serviceAdministratorNumber: AppData.serviceAdministratorNumber
        });
        if (responseCertType && responseCertType.Result && responseCertType.Result === 'Success') {
            this.setState({
                certificationTypes: responseCertType.codeDescriptionList

            });
        }
        let responseSpCertList: certTypes.TResponseDataType = await Post(loadSPCertifications.subUri, {}, {
            accountNumber: AppData.accountNumber, serviceAdministratorNumber: AppData.serviceAdministratorNumber
        });
        if (responseSpCertList && responseSpCertList.Result && responseSpCertList.Result === 'Success') {
            let { isMaxSelected, errors, isActionsDisabled } = this.state;
            if (responseSpCertList.certificationList && responseSpCertList.certificationList.length > 0) {
                if (responseSpCertList.certificationList.length >= config.maxLimt) {
                    isMaxSelected = true;
                    errors.type = '';
                    isActionsDisabled = true;
                }
            }
            else if (responseSpCertList.certificationList.length === 0) {
                errors.noData = this.props.t(captions["lbl-no-cert-added"].id, captions["lbl-no-cert-added"].default)
            }
            this.setState({
                certificationsList: responseSpCertList.certificationList,
                isMaxSelected: isMaxSelected,
                errors: errors,
                isActionsDisabled
            });
        }
    }
    render() {
        let { errors } = this.state;
        let { isActionsDisabled } = this.state;
        const { t } = this.props;
        let certifications = null;
        const auditData={entityType:config.entityType ,columnName:'certification'};
        if (this.state.certificationsList && this.state.certificationsList.length > 0) {
            certifications = (
                <React.Fragment>
                    <div className="row" style={{ borderTop: '1px groove', marginTop: '0.5%' }}>
                        <div className='col-10'><Label size="lg" label={t(captions["lbl-added-certifications"].id, captions["lbl-added-certifications"].default)} /></div>
                        {this.state.certificationsList && this.state.certificationsList.length > 0 ?
                            <div className='col-2'>{this.state.isEditCalled ? <ButtonMedium textDecorationLine='underline' label={`${t(captions["lbl-btn-save"].id, captions["lbl-btn-save"].default)}`} onClick={this.onClickSaveHandler} variant='link' style={{ maxHeight: '', marginLeft: '1%,min' }}></ButtonMedium> : <ButtonMedium label={`${t(captions["lbl-btn-edit"].id, captions["lbl-btn-edit"].default)}`} onClick={this.onClickEditHandler} variant='link' style={{ maxHeight: '', marginLeft: '1%,min' }} textDecorationLine='underline'></ButtonMedium>}</div>
                            : <React.Fragment></React.Fragment>}
                    </div>
                    <div className="row" >
                        <CertificationsTable dateChangeHandler={this.expiryDateChangeHanlder}
                            isEditCalled={this.state.isEditCalled}
                            inputChangeHandler={this.inputChangeHandler}
                            tableData={this.state.certificationsList} handleDelete={this.handleDelete}></CertificationsTable>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <div className="certification">
                <div className={`row ${classes.certTitle}` } >
                    <div className="col-10" >
                        <label>{`${t(captions["lbl-title"].id, captions["lbl-title"].default)}`} </label>
                    </div>
                    <div className="col-2">
                        {/* <Link */}
                        <ButtonMedium size='lg' textDecorationLine='underline' label={`${t(captions["lbl-btn-audit-cert"].id, captions["lbl-btn-audit-cert"].default)}`} onClick={() => { this.props.history.push(`/audit${userInfo.queryParams}`,auditData)}} variant='link' style={{ maxHeight: '', marginLeft: '10%,min' }}></ButtonMedium>
                    </div>
                </div>
                <div className="row  " style={{ marginLeft: '0%' }}>
                    <Label label={`${t(captions["lbl-add-new-cert"].id, captions["lbl-add-new-cert"].default)}`} size="lg" />
                </div>
                <div className="row pt-3" style={{ minHeight: 68 }}>
                    <div className="col-3 pr-1">
                        <Label label={`${t(captions["lbl-type"].id, captions["lbl-type"].default)}`}/>
                        {/* <Select disabled={isActionsDisabled}
                            value={this.state.selectedCertificationTypeValue}
                            onChange={this.onCertificationTypeSelectChange}
                            options={this.state.certificationTypes ? this.convertObjectToGenericType(
                                this.state.certificationTypes, 'description', 'codeValue', 'select'
                            ) : { label: '', value: '' }}
                        /> */}
                            <Select disabled={isActionsDisabled}
                            value={this.state.selectedCertificationTypeValue}
                            onChange={this.onCertificationTypeSelectChange}
                            options={this.state.certificationTypes }
                                labelKey= 'description'
                                valueKey='codeValue'
                        />
                    </div>
                    <div className="col-3 pr-1">
                        <Label label={t(captions["lbl-name"].id, captions["lbl-name"].default)}/>
                        <AutoCompleteInput
                            maxLimitReached={false}
                            minInput={config.minInput}
                            maxLimit={config.maxLimt}
                            code="CERT_NAME"
                            showSuggestions={true}
                            captureUserSelections={this.captureCertNameSelect}
                            handleInputChange={this.onCertificationNameChange}
                            readOnlyTrue={this.state.certificationNameInputReadOnly}
                            value={this.state.selectedCertificationName ? this.state.selectedCertificationName : this.state.certNameEntered}
                            disabled={this.state.isActionsDisabled}
                            isForceEmpty={this.state.isForceEmpty}
                        />

                    </div>
                    <div className="col-3 pr-1">
                        <div>
                            <Label label={t(captions["lbl-ref-number"].id, captions["lbl-ref-number"].default)} />
                            
                            <Label style={{paddingLeft:'33%'}} label={t(captions["lbl-optional"].id,captions["lbl-optional"].default)}/>
                        </div>
                        <div  >
                            <Input
                                name={"REF_INPUT"}
                                onChange={this.inputChangeHandler}
                                value={this.state.selectedReferenceNumber}
                                readOnly={this.state.certificationNameInputReadOnly}
                                disabled={this.state.isActionsDisabled}
                            ></Input>
                        </div>
                    </div>
                    <div className="col-3">
                        <div >
                            <Label label={t(captions["lbl-exp-date"].id, captions["lbl-exp-date"].default)} />
                        </div>
                        {/* <div style={{    fontFamily:'Apercu Pro',borderRadius: 0,border: '1px solid #000000'}}> */}
                        <div >
                            <DatePickerMedium
                                handleChange={this.expiryDateChangeHanlder}
                                readOnly={this.state.certificationNameInputReadOnly}
                                minDate={new Date()}
                                selectedDate={this.state.selectedExpiryDate}
                                disabled={this.state.isActionsDisabled}
                                fieldLabel={t(captions["lbl-exp-date"].id, captions["lbl-exp-date"].default)}
                            ></DatePickerMedium>
                        </div>

                    </div>
                </div>
                <div className="row" style={{ height: 18, marginTop: '0.3%' }}>
                    <div className="col-3" >
                        {errors.type ? <span style={{ color: 'red' }}>{errors.type}</span> : <React.Fragment />}
                    </div >
                    <div className="col-3">
                        {errors.name ? <span style={{ color: 'red', }}>{errors.name}</span> : <React.Fragment />}
                    </div>
                    <div className="col-3"/>
                    <div className="col-3">
                        {errors.date ? <span style={{ color: 'red' }}>{errors.date}</span> : <React.Fragment />}
                    </div>
                </div>
                <div className="row" style={{ marginLeft: '0%' }}>
                    <ButtonMedium label={t(captions["lbl-btn-add-to-list"].id, captions["lbl-btn-add-to-list"].default)} disabled={this.state.isActionsDisabled} variant="info"
                        style={{ minWidth: '8%' }} onClick={this.onClickAddHandler} />
                </div>
                {this.state.isMaxSelected ? <Label label={this.props.t(captions["err-max-selected"].id, captions["err-max-selected"].default)} style={{ color: 'red', marginTop: '1%' }} /> : <React.Fragment />}
                {this.state.certificationsList && this.state.certificationsList.length > 0 ? certifications : (errors.noData ? <span style={{ color: 'red', marginTop: '20%' }}>{t(captions["lbl-no-cert-added"].id, captions["lbl-no-cert-added"].default)}</span> : '')}
            </div>
        );
    }
}
export default withTranslation()(Certification)

