import React from "react";
import { Switch, Route } from "react-router-dom";
import Certifiation from "../../apps/service-provider/certifications/Certification";
import Insurance from "../../apps/service-provider/insurance/Insurance";

import { Notification } from "../components/Notification";
import Audit from "../components/Audit/Audit";
import AuditTable from "../components/Audit/AuditTable";
import {Rates,AddRate,EditRate,RateAudit} from "../../apps/service-provider/maintenance/rates/index";


// const Audit = React.lazy(() => import('../../apps/audit/Audit'));
export default function Routes() {
    return (
      <Switch>
        <Route path="/certifications" component={Certifiation} />
        <Route path="/insurance" component={Insurance} />
        <Route path="/audit" component={Audit} />
        <Route path="/auditTable" component={AuditTable} />
        <Route path="/sessionTimeOut" component={Notification} />
        <Route path="/rateMaintenance" component={Rates} />
        <Route path="/addRate" component={AddRate} />
        <Route path="/editRate" component={EditRate} />
        <Route path="/auditRate" component={RateAudit} />
      </Switch>
    );
}
