import React from "react";
import Button from "react-bootstrap/Button";
import Label from "./Label";
interface ButtonProps {
  active?: boolean;
  block?: boolean;
  variant?:
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light'
  | 'link'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-success'
  | 'outline-danger'
  | 'outline-warning'
  | 'outline-info'
  | 'outline-dark'
  | 'outline-light';
  size?: 'sm' | 'lg';
  type?: 'button' | 'reset' | 'submit';
  href?: string;
  disabled?: boolean;
  style?: {};
  onClick: Function;
  label: string;
  labelColor?:string
  color?:string,
  captionSize?:|'xsm'|'sm'|'md'|'lg'|'xlg'|'title',
  textDecorationLine?:'underline'|'overline' | 'line-through' | 'blink'  | 'spelling-error' | 'grammar-error'|'none'
  labelStyle?:{};
}
export default function ButtonMedium(props: ButtonProps) {
  let styleButtonMedium = {
    marginTop: '1%',
    // maxHeight: '1%',
    minHeight:'48px',
    minWidth: '25%',
    paddingLeft: 0,
    backgroundColor:'#377A80',
    color:'',
    fontFamily:'Apercu Pro'
  };
  if(props.variant&& (props.variant==='link' || props.variant.match('outline')))
  {
    styleButtonMedium.color='#377A80';
    styleButtonMedium.backgroundColor=''
  }
   if (props.style && props.style !== undefined) {
    styleButtonMedium = { ...styleButtonMedium, ...props.style };
  }
  if(props.disabled)
  {
    styleButtonMedium = { ...styleButtonMedium, 'color':'grey' };

  }


  return (
    <Button 
    variant={props.variant}
     type={props.type ? props.type : 'button'} 
     size={props.size ? props.size : 'sm'}     
      style={styleButtonMedium}
      href={'#'}
      disabled={props.disabled ? props.disabled :false}
      onClick={(event) => {
        props.onClick(event);
      }}
    >
      <Label size={props.captionSize?props.captionSize:'md'} textDecorationLine={props.textDecorationLine} style={{paddingTop:'10%' ,color:props.labelColor?props.labelColor:styleButtonMedium.color,...props.labelStyle}} label={props.label ? props.label : "Click me"}></Label>
    </Button>
  );
}
