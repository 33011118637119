import React from 'react'
import { useTranslation } from '../../utils'
import captions from '../../../commonLabels.json'
import GreenTick from  '../../images/GreenTick.png'
import error from '../../images/error1.png';
interface TPropsDataSaveAlert {
    alertMsg?: string,
    style?: any,
    type?: "failure" | "sucess",
}
export default function DataSaveAlert(props: TPropsDataSaveAlert) {
    const [t] = useTranslation();
     let msg = props.alertMsg ? props.alertMsg : (props.type ? (props.type === 'sucess' ? t(captions.common["api-save-success"]) : t(captions.common["api-save-fail"])) : t(captions.common["api-save-success"]))
    // let imgSrc = props.type ? (props.type === 'sucess' ? require('../../images/GreenTick.png') : require('../../images/error1.png')) : require('../../images/GreenTick.png');
    // console.log('img src',imgSrc);
    let defaultStyle = {
        maxHeight: '20',
        position: "absolute",
        zIndex: 10,
        padding: 0,
        marginLeft: '5%',
        marginTop: -40
    }
    let syles = props.style ? {
        ...defaultStyle, ...props.style
    } : defaultStyle
    let msgSpan = <span style={{ paddingLeft:'10px', wordWrap: "break-word", fontFamily: 'Apercu Pro', fontSize: 16, color: props.type ? (props.type === "sucess" ? '#000000' : 'red') : '#000000' }}> {msg}</span>;
    return (
        <React.Fragment >
            <div className="row pl-3" style={syles} >
            <img src={props.type ? (props.type === 'sucess' ? GreenTick :error) : GreenTick} style={{maxHeight:'19px'}}  alt='' />{msgSpan}</div>
                {/* <img src={props.type ? (props.type === 'sucess' ? require('../../images/GreenTick.png') : require('../../images/error1.png')) : require('../../images/GreenTick.png')} style={{maxHeight:'19px'}}  alt='' />{msgSpan}</div> */}
        </React.Fragment>
    )
}
