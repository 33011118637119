import React, { useState, useEffect } from "react";
import {
    loadRatesList, loadRateDetails, Post, AppData, userInfo
} from  "./index";
import delay from "delay";
import {rateTypes,RateDetails,ButtonMedium,RateSearch,useTranslation,RateListTable,isEmpty,Label,captions} from "./index";
const Status = {
    active: "active",
    inactive: "inactive",
};
export default function Rates(props) {
    let initialState = {
        isShowDetails: false,
        containerWidth: 12,
        productLines: [],
        selectedProductLineCode: "",
        spRateList: [],
        activeChecked: false,
        inactiveChecked: false,
        amounts: [],
        isCollpased: false,
        widthMultiplier: 1
    };
    const [t] = useTranslation();
    const [state, setState] = useState(initialState);
    const [rowData, setRowData] = useState({})
    async function getSpRateList(productLine, activeChecked, inactiveChecked) {
        //delyed api call to let user object update finished
        await delay(200);
        let payLoad = {};
        payLoad = {
            serviceAdministratorId: AppData.serviceAdministratorNumber,
            serviceProviderId: AppData.serviceProviderId,
        };
        if (productLine) {
            payLoad = { ...payLoad, productLineCode: productLine };
        }
        if (activeChecked && inactiveChecked) {
            //dont use status in request- show all records
        } else if (activeChecked) {
            payLoad = { ...payLoad, status: Status.active };
        } else if (inactiveChecked) {
            payLoad = { ...payLoad, status: Status.inactive };
        }
        return await Post(loadRatesList.subUri, {}, payLoad);
    }
    useEffect(() => {
        async function fetchRateListOnPageLoad() {
            await getSpRateList(null, false, false).then(
                (data: rateTypes.TResponseDataType) => {
                    data && data.rates
                        ? setState({ ...state, spRateList: data.rates })
                        : setState({ ...state, spRateList: [] })
                }
            );
        }
        fetchRateListOnPageLoad();
    }, []);
    async function fetchRateDetails(pricingMatrixId: string) {
        return await Post(
            loadRateDetails.subUri, {},
            {
                serviceAdministratorId: AppData.serviceAdministratorNumber, pricingMatrixId: pricingMatrixId, serviceProviderId: AppData.serviceProviderId
            }
        );
    }
    async function onClickDetailHandler(e: MouseEvent, rowData: rateTypes.TRateItem) {
        e.preventDefault();
        let currentContainerWidth = state.containerWidth === 12 ? state.containerWidth - 5 : state.containerWidth;
        let currentWidthMultiplier = state.widthMultiplier === 1 ? state.widthMultiplier * 0.45 : state.widthMultiplier;
        setRowData(rowData);
        const updatedData = { isShowDetails: true, containerWidth: currentContainerWidth, isCollpased: true, widthMultiplier: currentWidthMultiplier };
        await fetchRateDetails(rowData.pricingMatrixId).then(
            (data: rateTypes.TResponseDataType) => {
                data && data.amounts
                    ? setState({ ...state, amounts: data.amounts, ...updatedData })
                    : setState({ ...state, amounts: [], ...updatedData })
            }
        );
    }
    async function captureProductLineSelect(selecedProductLineOption) {
        if (selecedProductLineOption && selecedProductLineOption.value) {
            setState({ ...state, selectedProductLineCode: selecedProductLineOption.value })
            let response: rateTypes.TResponseDataType = await getSpRateList(selecedProductLineOption.value, state.activeChecked, state.inactiveChecked);
            if (response && response.rates ) {
                setState({ ...state, selectedProductLineCode: selecedProductLineOption.value, spRateList: response.rates })
            }
        }
        else {
            setState({ ...state, selectedProductLineCode: '' })
            let response: rateTypes.TResponseDataType = await getSpRateList(null, state.activeChecked, state.inactiveChecked);
            if (response && response.rates ) {
                setState({ ...state, selectedProductLineCode: '', spRateList: response.rates })
            }
        }
    }
    async function onClickDismissHandler() {
        setState({ ...state, isShowDetails: false, containerWidth: 12, isCollpased: false, widthMultiplier: 1, amounts: [] });
    }
    async function onCLickStatusCheckChangeHandler(e) {
        let isActiveChecked = state.activeChecked;
        let isInactiveChecked = state.inactiveChecked;
        if (e.target.id === Status.active) {
            isActiveChecked = state.activeChecked ? false : true;
            setState({ ...state, activeChecked: isActiveChecked })
        }
        if (e.target.id === Status.inactive) {
            isInactiveChecked = state.inactiveChecked ? false : true;
            setState({ ...state, inactiveChecked: isInactiveChecked })
        }
        getSpRateList(
            state.selectedProductLineCode,
            isActiveChecked,
            isInactiveChecked
        ).then(
            (data: rateTypes.TResponseDataType) => {
                data && data.rates ?
                    setState({ ...state, spRateList: data.rates, activeChecked: isActiveChecked, inactiveChecked: isInactiveChecked })
                    : setState({ ...state, activeChecked: isActiveChecked, inactiveChecked: isInactiveChecked, spRateList: [] });
            }
        );;
    }

    async function onClickAddNewRate() {
  
        props.history.push(`/addRate${userInfo.queryParams}`);
    }

    return (

        <div className="row">
            <div className={`col-${state.containerWidth}`}>
                <div className="row">
                    <div className='col-9'>
                        <Label size="title" label={t(captions["lbl-title"])}></Label>
                    </div>
                    <div className='col-3'>
                        <ButtonMedium labelStyle={{ width: 'max-content' }} onClick={(e) => {
                            e.preventDefault();
                            onClickAddNewRate();
                        }} label={t(captions["lbl-btn-add-new-rate"])} variant='outline-info' type='button' size='lg' style={{ paddingLeft: '5%' }} ></ButtonMedium>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Label size="xlg" label={AppData.serviceProviderName}></Label>
                    </div>
                </div>
                <div className="row" style={{ marginLeft: "0.2%" }}>
                    <RateSearch activeChecked={state.activeChecked}
                        inactiveChecked={state.inactiveChecked}
                        statusCheckBoxChange={onCLickStatusCheckChangeHandler}
                        rateList={state.spRateList}
                        captureProductLineSelect={captureProductLineSelect}
                        isCollpased={state.isCollpased}
                        isEditRate={false}
                    ></RateSearch>
                </div>
                <div className="row pt-2 pl-2">
                    <RateListTable
                        selectRateItem={onClickDetailHandler}
                        rateList={state.spRateList}
                        itemsPerPage={10}
                        fetchData={null}
                        rateItemCount={
                            state.spRateList && !isEmpty(state.spRateList) ? state.spRateList.length : 0
                        }
                        widthMultiplier={state.widthMultiplier}
                    ></RateListTable>
                </div>
            </div>
            {state.isShowDetails ? <div className='col-5'><RateDetails  props={props} rowData={rowData} data={state.amounts} onClickDismiss={onClickDismissHandler} /> </div> : <React.Fragment />}
        </div>
    );
}
