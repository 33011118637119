import React from "react";
import '../styles/Input.module.scss';
import captions from'../../commonLabels.json'
import { useTranslation } from "../utils";
type TInputProps = {
  name?: string,
  objectId?: number | String,
  value: string | number,
  readOnly?: | true | false,
  disabled?: | true | false
  onChange: Function
  maxLength?: number
  isError?: boolean
  errorTxt?: string
}
const input = {
  width: '100%',
  outline: 'none',
  minHeight: '48px',
  border: '1px solid #000000'
}

export default function Input(props: TInputProps) {
const [t]=useTranslation();
  let styles = props.isError ? { ...input, border: '1px solid #f00' } : input;
  let errors = props.isError ? 
  (
    props.errorTxt ? 
    (<React.Fragment>
      <span style={{ color: 'red', wordWrap: 'break-word' }}>{props.errorTxt}</span>
    </React.Fragment>) : 
    (<React.Fragment>
      <span style={{ color: 'red', wordWrap: 'break-word' }}>{t(captions.input["validation-error"])}</span>
    </React.Fragment>))
    
    
    : <React.Fragment />


  return (
    <React.Fragment>
      <input
        style={{ ...styles }}
        name={props.name}
        onChange={(e) => {
          if (props.onChange && props.onChange !== undefined) {
            props.onChange(e.target.value, props.objectId);
          }
        }}
        value={props.value}
        readOnly={props.readOnly ? props.readOnly : false}
        disabled={props.disabled ? props.disabled : props.readOnly}
        maxLength={props.maxLength ? props.maxLength : 30}
      ></input>
{errors}
    </React.Fragment>
  );
}
