import React, { memo } from 'react';
import Label from '../Label';

interface Iprops {
  className?: string,
  label: string,
  name: string,
  // isChecked: true | false,
  onChange: Function
  id?: string
  selectedValue:string
}
const Radiobutton = (props: Iprops) => (
  <React.Fragment>
    <input
      type="radio"
      checked={props.selectedValue===props.id}
      value={props.label}
      name={props.name}
      onChange={(e) => {
        props.onChange(e)
      }
      }
      id={props.id}
      style={{ maxHeight: '24px !important', minHeight: '24px', width: '24px', marginLeft: '1%' }}
    />
    <Label size='lg' label={props.label} style={{ paddingLeft: '10px' }}></Label>

  </React.Fragment>
);

export default memo(Radiobutton);