import React from 'react';
import classes from '../../modules/styles/Select.module.scss';
import  {useTranslation}  from '../../modules/utils'
import commonLabels from '../../commonLabels.json'

interface PropsSelect {
  label?: string,
  options: []|any,
  value: string | number,
  onChange?: Function;
  disabled?:|true|false,
  labelKey?:string,
  valueKey?:string

}
interface TypeOption {
  value: string | number,
  label: string | number
}

export default function Select(props: PropsSelect) {
  let inOptions=props.options;
  const getText=useTranslation()[0];
  let options=[];

 const  convertObjectToGenericType=(object: any, labelKey: string, valueKey: string)=> {
    if (object && object.length > 0) {
        let newObj: any = [];
        object.forEach(function (item: any, index) {
            let newItem = {
                label: item[labelKey],
                value: item[valueKey],
                data: item
            };
            newObj.push(newItem);
        });
        return newObj;
    }
    else { return {} }
}
if(props.options&& props.labelKey&& props.valueKey)
{
 options= convertObjectToGenericType(inOptions,props.labelKey,props.valueKey);
}
else{
  options=inOptions;
}
  const optionList = (options && options.length>0?
    <React.Fragment>
      {options.map((option: TypeOption, index: number) => {
        return (
          <option key={`opt-${props.label}${index}`} value={option.value} >
            {option.label}
          </option>
        );
      })}
    </React.Fragment>
    :<React.Fragment></React.Fragment>
  );

  return (
    <div >
      <div className={classes['select-custom-div']}>
        <select className={classes['select-custom-dropdown']} disabled={props.disabled} onChange={(e)=>{ props.onChange(e);}} key={`sel-${props.label}`} value={props.value} >
        <option key={`opt-${props.label}$`} value={''} >
            {getText(commonLabels.common["lbl-select-choose-one"])}
          </option>
        {optionList}
      </select>
      </div>
    </div>
  );

}

