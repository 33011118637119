import React from 'react'
import classes from  '../styles/label.module.scss';
interface TLabelProps{
label:string,
style?:React.CSSProperties,
size?:|'xsm'|'sm'|'md'|'lg'|'xlg'|'title',
children?:React.ReactNode,
textDecorationLine?:'underline'|'overline' | 'line-through' | 'blink'  | 'spelling-error' | 'grammar-error'|'none'

}

const defaultClass='labels-';
export default function Label(props:TLabelProps) {
  const  defaultStyle={
    fontFamily:'Apercu Pro',
    color:'#000000',
    textDecorationLine:props.textDecorationLine?props.textDecorationLine:'none',
    marginBottom:0,
    marginTop:'1%'
    }
  let customStyle={...defaultStyle,...props.style};

  let className=props.size?`${defaultClass}${props.size}`:`${defaultClass}md`;
    return (
      <React.Fragment>
          <label className={classes[className]} style={customStyle}>{props.label}  {props.children}</label>
      </React.Fragment>
    )
}
