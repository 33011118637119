import React, { useState } from 'react';
import classes from './RateSearch.module.scss';
import { useTranslation,Checkbox,captions,Label,AutoCompleteInput,rateTypes } from '../index';
import { Post, AppData ,loadProductLines,isEmpty} from '../index';

const config = {
    maxLimt: 10,
    minInput: 3,
    entityType: 'com.servicebench.cribbage.model.common.ServiceProviderCert',
};
const Status = {
    active: "active",
    inactive: "inactive",
};
const RateSearch = ({
    activeChecked,
    inactiveChecked,
    statusCheckBoxChange,
    rateList,
    captureProductLineSelect,
    isCollpased,
    isEditRate
}) => {
    const [t] = useTranslation();
    let searchFilter = {
        productLineEntered: '',
        selectedProductLine: null
    }
    let statusBarWidth=isCollpased?{maxWidth:'100%'}:{maxWidth:'18%'};
    const [state, setState] = useState(searchFilter);
    async function onProductLineInputChange(input: string, cb: Function) {
        setState({ ...state, productLineEntered: input });
        if (input) {
            if (input.length >= config.minInput) {
                let response: rateTypes.TResponseDataType = await Post(loadProductLines.subUri, {}, {
                    serviceAdministratorId: AppData.serviceAdministratorId, productLineDescription: input
                });
                if (response && response.Result && response.Result === 'Success' && response.productLineList && response.productLineList.length > 0) {
                    let formattedObject = convertObjectToGenericType(response.productLineList, 'productLineDescription', 'productlineCode', null)
                    cb(formattedObject);
                }
                else {
                    cb(null);
                }
            }

        } else if (isEmpty(input)) {
            captureProductLineSelect(null);
        }
    }
    function convertObjectToGenericType(object: any, labelKey: string, valueKey: string, inputType: string) {
        if (object && object.length > 0) {
            let newObj: any = [];
            object.forEach(function (item: any, index) {
                let newItem = {
                    label: item[labelKey],
                    value: item[valueKey],
                    data: item
                };
                newObj.push(newItem);
            });
            return newObj;
        }
        else { return {} }
    }
    function onProductLineSelect(inputCode, selectedOption) {
        if (selectedOption && selectedOption.value) {
            captureProductLineSelect(selectedOption);
            setState({ ...state, productLineEntered: selectedOption.label, selectedProductLine: selectedOption.value })
        }
    }

    return (
        <React.Fragment>
            <div className="row col-6">
                <AutoCompleteInput
                    maxLimitReached={false}
                    minInput={config.minInput}
                    maxLimit={config.maxLimt}
                    code=""
                    showSuggestions={true}
                    captureUserSelections={onProductLineSelect}
                    handleInputChange={onProductLineInputChange}
                    readOnlyTrue={false}
                    value={state.productLineEntered}
                    disabled={isEditRate?true:false}
                    isForceEmpty={true}
                    t={t}
                    placeholder={t(captions["lbl-place-holder-product-line"])}
                />
            </div>
            <div className="row col-6" >
                            <div className="col-4" style={{...statusBarWidth}}>
                                <Label label={t(captions["lbl-status"])} size="xlg"></Label>
                            </div>
                            <div className=" col-3" style={{...statusBarWidth}}>
                                <Checkbox label={t(captions["lbl-active"])}
                                isChecked={activeChecked}
                                onChange={statusCheckBoxChange}
                                className={classes['rate-checkbox']}
                                id={Status.active}
                            /></div>
                            <div className="col-5" style={{...statusBarWidth}}>
                                <Checkbox label={t(captions["lbl-inactive"])}
                                isChecked={inactiveChecked}
                                onChange={statusCheckBoxChange}
                                className={classes['rate-checkbox']}
                                id={Status.inactive}
                            /></div>
            </div>
        </React.Fragment>
    );


}

export default RateSearch;
