import classes from  '../../modules/styles/AutoCompleteInput.module.scss';
import {withTranslation} from 'react-i18next'
import React, { Component } from 'react';
import labels from '../../commonLabels.json'
type TOption = {
    value: string,
    label: string,
    id?: number | string,
    args?:{}
}
type TOptions = {
    value: string,
    label: string,
    id?: number | string,
    args?:{}
}[]

interface IState {
    activeOption: number,
    filteredOptions: TOptions,
    showOptions: boolean,
    userInput: string,
    userSelections: TOptions,
    isMaxSelected: boolean,
    noOptionAvailable: boolean,
    style1?: { overflowY: any | string };
    options: TOptions
}

interface IProps {
    maxLimitReachedErrMsg?: String,
    maxLimitReached?: boolean,
    userSelections?: TOptions,
    minInput?: number,
    maxLimit?: number,
    placeholder?: string,
    options?: TOptions,
    code?: string,
    handleInputFoucs?: Function,
    showSuggestions: boolean,
    captureUserSelections: Function,
    handleInputChange: Function,
    overFlowLimit?: number,
    readOnlyTrue?: boolean
    value?: string ,
    disabled?: true | false,
    isForceEmpty?: | true | false,
    t:Function
}
export class AutoCompleteInput extends Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            activeOption: 0,
            filteredOptions: [],
            showOptions: false,
            userInput: '',
            userSelections: [],
            isMaxSelected: false,
            noOptionAvailable: false,
            style1: { overflowY: "" },
            options: []
        };
        this.recieveOptionsFromParent = this.recieveOptionsFromParent.bind(this);
        this.recieveOptionsFromParentApiCall = this.recieveOptionsFromParentApiCall.bind(this);
        this.onChange=this.onChange.bind(this);
        this.onClick=this.onClick.bind(this);
        this.onFocus=this.onFocus.bind(this);
        this.onKeyDown=this.onKeyDown.bind(this);
    }
    recieveOptionsFromParent(object) {
        this.setState({
            options: object
        });
    }
    recieveOptionsFromParentApiCall(searchApiResponse) {
        let filteredOptions: TOptions = [];
        let showOptions = false;
        let { noOptionAvailable, style1 } = this.state;
        if (searchApiResponse && !searchApiResponse.Code) {
            noOptionAvailable = false;
            filteredOptions = [...searchApiResponse];
            if (filteredOptions.length > 0) { showOptions = true }
        }
        else if (searchApiResponse && searchApiResponse.Code) {
            noOptionAvailable = true;
        }
        else if (null===searchApiResponse)
        {
            // noOptionAvailable = true;
        }
        if (filteredOptions.length > 6) {
            style1.overflowY = 'scroll'
        }
        if (filteredOptions.length === 0) {
            // noOptionAvailable = true;
            
        }
        this.setState({
            activeOption: 0,
            filteredOptions: filteredOptions,
            showOptions: showOptions,
            noOptionAvailable: noOptionAvailable,
            style1
        });
    }

    onChange = (e: object | any) => {
        let userInput = '';
        let {noOptionAvailable,showOptions}=this.state;       
            userInput = e.currentTarget.value;
            if(!userInput)
            {
                noOptionAvailable=false;
            }
            else if(userInput.length<this.props.minInput)
            {
                showOptions=false ; 
            }
            this.props.handleInputChange(userInput, this.recieveOptionsFromParentApiCall);           
            this.setState({
                showOptions: showOptions,
                noOptionAvailable
            });

    }


    onClick = (option: TOption) => {
        this.props.captureUserSelections(this.props.code, option);
        this.setState({
            activeOption: 0,
            filteredOptions: [],
            showOptions: false,
            userInput: option.label,
        });
    };
    onKeyDown = (e: object | any) => {
        const { activeOption, filteredOptions } = this.state;
        if (e.keyCode === 13) {
            if (activeOption) {
                let selectedOption: any = filteredOptions[activeOption];
                this.props.captureUserSelections(this.props.code, selectedOption);
                this.setState({
                    activeOption: 0,
                    showOptions: false,
                    userInput: `${selectedOption.label}`
                });
            }
        } else if (e.keyCode === 38) {
            if (activeOption === 0) {
                return;
            }
            this.setState({ activeOption: activeOption - 1 });
        } else if (e.keyCode === 40) {
            if (activeOption === filteredOptions.length - 1) {
                console.log(activeOption);
                return;
            }
            this.setState({ activeOption: activeOption + 1 });
        }

    };
    onFocus = () => {
        if (this.props.handleInputFoucs && this.props.handleInputFoucs !== undefined) {
            this.props.handleInputFoucs(this.props.code);
        }
        this.setState({
            activeOption: 0,
            showOptions: false,
            userInput: ''
        });

    };
    render() {

        const {
            onChange,
            onKeyDown,
            state: { activeOption, filteredOptions, showOptions }
        } = this;
        let optionList;
        let userInput = this.props.value;
        // let userInput = this.props.value ? this.props.value : this.state.userInput;
        // userInput = this.props.isForceEmpty && this.props.value ? '' : userInput;
        if (!this.props.maxLimitReached) {
            if (showOptions && this.props.showSuggestions && userInput && !this.state.noOptionAvailable) {
                if (this.state.filteredOptions.length>0 &&(userInput && userInput.length>0) ) {
                    optionList = (
                        <ul className={classes["options"]} style={Object.assign({}, this.state.style1)}>
                            {filteredOptions.map((option: TOption, index: number) => {
                                let className;
                                if (index === activeOption) {
                                    className = 'option-active';
                                }
                                return (
                                    <li className={classes[className]} data-item={{ option }} key={`opt-${index}`} onClick={(e) => this.onClick(option)}>
                                        {`${option.label}`}
                                    </li>
                                );
                            })}
                        </ul>
                    );
                }
            } else {
                if ( this.state.noOptionAvailable && userInput) {
                    optionList = (
                        <div className={classes['no-options']}>
                            <em>{this.props.t(labels.common["err-no-options-found"].id,labels.common["err-no-options-found"].default)}</em>
                        </div>
                    );
                }
            }
        }
        else {
            if (this.props.maxLimitReached) {
                optionList = (
                    <div className= {classes['maxSelectedError']} style={{ marginTop: 10 }}>
                        <label className={classes['maxSelectedErrorLabel']}>{this.props.maxLimitReachedErrMsg}</label>
                    </div>
                );
            }
        }
        return (
            <React.Fragment>
                <div className={classes['searchDiv']}>
                    <input
                        type="text"
                        className= {classes["input-box"]}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        value={userInput}
                        onFocus={this.onFocus}
                        placeholder={this.props.placeholder}
                        readOnly={this.props.readOnlyTrue ? this.props.readOnlyTrue : false}
                        disabled={this.props.disabled ? this.props.disabled : this.props.readOnlyTrue}

                    />
                    {optionList}
                </div>
                
            </React.Fragment>
        );
    }
}
export default withTranslation()(AutoCompleteInput);