import React, { useState, useEffect } from "react";
import {RateTypeTable,addRateInfo,Post,DataSaveAlert, AppData, loadCodeDescription,Label,captions,rateTypes,useTranslation,NewRate,ButtonMedium,arrowLeft} from "../index";

export default function AddRate(props) {
    let initialState = {
        isShowDetails: false,
        containerWidth: 12,
        productLines: [],
        amountTypes: [],
        amountTypesB: [],
        selectedProductLineCode: "",
        spRateList: [],
        activeChecked: false,
        inactiveChecked: false,
        statusRadio: "",
        amounts: [],
        hourlyChecked: false,
        fixedChecked: false,
        flatChecked: false,
        amountTypeObjList: [],
        amountDataList: [],
        selectedStatus: "active",
        selectedRateType: "FIXED",
        isEdit: true,
        isProductLineSelected:false,
        addApiErrors: [],	
        apiErrorMsg: "",
        apiUpdateSuccess:false

    };
    const [t] = useTranslation();
    const [state, setState] = useState(initialState)
    const prodLineError=  t(captions["lbl-prodlineErr"]);
    const [showAPIStatus, setShowAPIStatus] = useState(false);
    const [isApiSuccess, setIsApiSuccess] = useState(false);
    const [apiFailMsg, setApiFailMsg] = useState('');

    async function captureProductLineSelect(selecedProductLineOption) {
        if (selecedProductLineOption && selecedProductLineOption.value) {
            setState({ ...state, selectedProductLineCode: selecedProductLineOption.value, isProductLineSelected:true })
        }
        else {
            setState({ ...state, selectedProductLineCode: '' })
        }
    }
    async function onCLickStatusRadioChangeHandler(e) {
        setState({ ...state, selectedStatus: e.target.id });
    }

    async function onCLickTypeRadioChangeHandler(e) {
        setState({ ...state, selectedRateType: e.target.id });
    }

		
    async function onClickSaveHandler(event, object) {	
        let payLoad = {};	
        let amountInfoList = state.amountTypes;	
        let amountTypeAPiList = [];	
        if (amountInfoList && amountInfoList.length > 0) {	
            amountInfoList.forEach((item: rateTypes.TAmountType, index: number) => {	
                let amountInfoApiData = {	
                    amount: item.amount ? Number(item.amount) : 0,	
                    effectiveDate: item.effectiveDate ? item.effectiveDate : new Date().toISOString(),	
                    amountTypeCode: item.amountTypeCode	
                }	
                amountTypeAPiList.push(amountInfoApiData);// array to use in add api call(without the amounttypedescription field)	
                item.amount = item.amount ?item.amount : '0.00';	
                item.effectiveDate = item.effectiveDate ? item.effectiveDate : new Date().toISOString();	
            })	
        }	
        setState({ ...state, amountTypes: amountInfoList });	
        payLoad = {	
            serviceAdministratorId: AppData.serviceAdministratorNumber,	
            accountNumber: AppData.accountNumber,	
            productLineCode: state.selectedProductLineCode,	
            status: state.selectedStatus,	
            pricingCategoryCode: state.selectedRateType,	
            amounts: amountTypeAPiList
        };
        let response: rateTypes.TResponseDataType = await Post(addRateInfo.subUri, {}, payLoad);
        let apiErrorList = [];
        let apiError: string;
        
        if (response) {
            window.scrollTo(0, 0);
            if (response.errors) {
                setIsApiSuccess(false);
                let errorString='';
                
                response.errors.forEach(function (item, index) {
                    apiError = item.description;
                    if (index===0)
                    {
                    errorString=item.description
                    }
                    else  {
                        errorString=`${errorString} , ${item.description}`
                    }
                    apiErrorList.push(apiError);
                });
                setApiFailMsg(errorString);
                setState({ ...state, addApiErrors: apiErrorList, apiErrorMsg: apiError });
            }
            else if (response.matrixId) {
                if (amountInfoList && amountInfoList.length > 0) {
                    amountInfoList.forEach((item: rateTypes.TAmountType, index: number) => {
                        try {
                            item.amount = item.amount? Number.parseFloat(item.amount).toFixed(2) : '0.00';
                        } catch (err) {
                            console.log(`error in add rate while formatting amount for 2 decimal places for pricing matrix id ${response.matrixId} for amount type ${item.amountTypeCode} `)
                        }
                    })
                }	
                setIsApiSuccess(true);
                setState({ ...state, isEdit: false, apiUpdateSuccess: true });
            }
            setShowAPIStatus(true);
        }
    }

    async function expiryDateChangeHanlder(date: Date, id) {
        if (id && id !== undefined) {
            let amountInfoList = state.amountTypes;
            if (amountInfoList && amountInfoList.length > 0) {
                amountInfoList.forEach((item: rateTypes.TAmountType, index: number) => {
                    let objectId = item.amountTypeCode;
                    if (item && id === objectId) {
                        item.effectiveDate = date ? date.toISOString() : '';
                    }
                })
            }
            setState({ ...state, amountTypes: amountInfoList });
        }
    }


    async function inputChangeHandler(value, objectId) {
        let amountInfoList: rateTypes.TAmountTypes = state.amountTypes;
        if (amountInfoList && amountInfoList.length > 0) {
            amountInfoList.forEach((item: rateTypes.TAmountType, index: number) => {
                let tempId = item.amountTypeCode;
                if (item && objectId === tempId) {
                    item.amount = value && value !== "" && value instanceof String ? Number(value) : value;	
                }
            })
        }
        setState({ ...state, amountTypes: amountInfoList });
    }

    useEffect(() => {

        async function fetchAmountTypesOnLoad() {
            let responseAmountTypeList: rateTypes.TResponseDataType = await Post(loadCodeDescription.subUri, {}, {
                "codeType": 17762,
                "serviceAdministratorId": AppData.serviceAdministratorId
            });
            if (responseAmountTypeList && responseAmountTypeList.Result && responseAmountTypeList.Result === 'Success') {
                let amountTypeObjList = responseAmountTypeList.codeDescriptionList;

                let newObjList = [];
                let amountList = amountTypeObjList;
                if (amountList && amountList.length > 0) {
                    amountList.forEach((item: rateTypes.TCodeDescription, index: number) => {
                        let newObj = {
                            amountTypeDescription: item.description,
                            effectiveDate: "",
                            amount: '',
                            amountTypeCode: item.codeValue
                        }
                        newObjList.push(newObj);
                    })

                }
                setState({ ...state, amountTypes: newObjList })
            }
        }

        fetchAmountTypesOnLoad();
    }, []);
    const updateSuceesDiv = showAPIStatus && isApiSuccess ? <DataSaveAlert style={{ marginTop: '-2.5%', marginLeft: '17%' }} /> : '';
    const updateFailDiv = showAPIStatus && !isApiSuccess ? <DataSaveAlert type="failure" alertMsg={apiFailMsg} style={{ marginTop: '-2.5%', marginLeft: '17%' }} /> : '';
    return (
        <React.Fragment>
            <div className='row' >
                <div className='col-6 pl-0'>
                <img style={{ paddingTop: '3.5%' }}
                        alt="ServiceBench"
                        title="ServiceBench (logo)"
                        aria-hidden="true"
                        src={arrowLeft}
                        onClick={() => { props.history.goBack() }}
                    />
                    <ButtonMedium style={{ minWidth: 0 }} captionSize="md" variant='link' label={t(captions["lbl-link-back-rate-maintenance"])} onClick={() => { props.history.goBack() }} />
                </div>
            </div>

            {/* <div className="row" style={{ height: 28, marginTop: '0.3%' }}>
                <div className="col-6" >
                    {state.apiErrorMsg !== "" ? <span style={{ color: 'red' }}>{state.apiErrorMsg}</span> : <React.Fragment />}
                </div >
            </div> */}
            
            {/* <div className="row" style={{ height: 28, marginTop: '0.3%' }}>
                <div className="col-6" >
                    {state.apiUpdateSuccess ? <span style={{ color: 'green' }}>{apiSuccessMsg}</span> : <React.Fragment />}
                </div >
            </div> */}
            <div className="row pl-3">
                <Label size="title" label={t(captions["lbl-add-title"])} style={{paddingTop:0}}></Label>
            </div>
            {updateSuceesDiv}
            {updateFailDiv}
            <NewRate
                statusRadioChange={onCLickStatusRadioChangeHandler}
                    rateType={""}
                    rateTypeRadioChange={onCLickTypeRadioChangeHandler}
                    captureProductLineSelect={captureProductLineSelect}
                    isEditRate={false}
                    detailMetaData={null}
                    selectedProductLine={null}
                    selectedStatus={state.selectedStatus}
                    selectedRateType={state.selectedRateType}
                    isProductLineSelected={state.isProductLineSelected}
                    prodLineError={prodLineError}
                ></NewRate>
            <div className="row">
                <RateTypeTable dateChangeHandler={expiryDateChangeHanlder}
                    inputChangeHandler={inputChangeHandler}
                    isEditCalled={state.isEdit}
                    tableData={state.amountTypes} ></RateTypeTable>
            </div>
            <div className="row" style={{ marginLeft: '70%' }}>
                <ButtonMedium size='lg'
                    label={t(captions["lbl-btn-save"])}
                    onClick={onClickSaveHandler}
                    style={{ minWidth: '30%', marginLeft: '10%' }} />
            </div>

        </React.Fragment>
    );
}
