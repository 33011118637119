import * as React from 'react'
import { useTranslation as _useTranslation } from "react-i18next";

import { format } from "./strings";

interface Copy {
  id: string;
  default: string;
  description: string;
}

export const useTranslation = () => {
  const [t, i18n, ready] = _useTranslation();

  const u = (copy: Copy, options?: { values?: any[] }) => {
    if (options && options.values) {
      return format(t(copy.id, `${copy.default}`), ...options.values);
    }

    return t(copy.id, `${copy.default}`);
  };

  return [u, i18n, ready] as const;
};

export type UseTranslationResponse = ReturnType<typeof useTranslation>;

export const fromCopy = (copy: Copy): React.FC => () => {
  const [t] = useTranslation()

  return React.createElement(React.Fragment, null, t(copy))
}
export const getTransaltor=(t)=>{

  const u = (copy: Copy, options?: { values?: any[] }) => {
    if (options && options.values) {
      return format(t(copy.id, `${copy.default}`), ...options.values);
    }
    return t(copy.id, `${copy.default}`);
  };

  return [u] as const;

}