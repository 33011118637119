import classes from  '../../modules/styles/Confirmation.module.scss';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { ReactChild } from "react";
import { useTranslation } from '../utils';
import captions from '../../commonLabels.json'
import ButtonMedium from './ButtonMedium';
type ConfirmationType={
    show:boolean,
     onHide?:Function, 
     imgSrc?:string,
     msgPart1?:string,
     msgPart2?:string,
     message?:ReactChild,
     labelCancel?:string,
     labelOk?:string,
     actionOk:Function,
     actionCancel:Function 
}
const  Confirmation=(props:ConfirmationType)=>  {
    
    const [t]= useTranslation();
    let imageSrc=props.imgSrc?props.imgSrc:require('../../modules/images/warning.png');
    let labelOk=props.labelOk?props.labelOk:t(captions.common["confirm-box-lbl-btn-ok"]);
    let labelCancel=props.labelCancel?props.labelCancel:t(captions.common["confirm-box-lbl-btn-cancel"]);
    let msg1=props.msgPart1?props.msgPart1:t(captions.common["confirm-box-confirm-msg-part-1"]);
    let msg2=props.msgPart2?props.msgPart2:t(captions.common["confirm-box-confirm-msg-part-2"]);

    let message=props.message?props.message:(
        <React.Fragment>
          <h3>{msg1}</h3>          
          <h6>{msg2} </h6>
          <br></br>
        </React.Fragment>
      );;

        return (
            <Modal show={props.show} onHide={props.onHide} size="lg" style ={{marginTop:150}} >
                <Modal.Body>
                    <div className={classes["Center"]}>
                        <img src={imageSrc} alt='' />
                       {message}
                        <div>
                        <ButtonMedium label={labelCancel}  style ={{minHeight:'24px'}} labelStyle={{marginTop:'none',paddingTop:'none',minHeight:'24px'}} variant="outline-dark" key="Ocancel-conirm " onClick={()=>props.actionCancel()}></ButtonMedium>
                        <ButtonMedium label={labelOk} style ={{marginLeft:15,minHeight:'24px'}} labelStyle={{marginTop:'none',paddingTop:'none',minHeight:'24px'}} variant="primary" key="OK-confirm" onClick={()=>props.actionOk()}></ButtonMedium>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        );    
}

export default Confirmation;