import { loadCodeDescription } from '../../constants/apiConst';
import { Post, AppData } from '../../modules/apis/apiUtitlity';
export interface TCodeDescriptions extends Array<TCodeDescription> {

}
export interface TCodeDescription {
    codeValue: string,
    codeType?: number,
    codeValue2?: string,
    codeValue3?: string,
    description: string,
    companyId?: number

}
export interface TCodeDescriptionResponse {
    Result?: string,
    Errors?: [{ code: string, msg: string }],
    errors?: [{ code: string, description: string }],
    codeDescriptionList?: TCodeDescriptions | any
}
export async function fetchCodeDescriptions(payLoad) {
    let codeDescriptionsList: TCodeDescriptionResponse = await Post(loadCodeDescription.subUri, {}, {
        "serviceAdministratorId": AppData.serviceAdministratorId,"active":1, ...payLoad
    }
    );
    if (codeDescriptionsList && codeDescriptionsList.Result) {
        return codeDescriptionsList.codeDescriptionList;
    }
}