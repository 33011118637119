import {auditService,codeDescriptionService} from '../../../../../modules/services/index';

export async function fetchSaConfiguredRateTypes() {
    return await codeDescriptionService.fetchCodeDescriptions({"codeType":12034});
}
export async function fetchSaConfiguredRateAmountTypes() {
    return await codeDescriptionService.fetchCodeDescriptions({"codeType":17762});
}
export async function fetchRateAudits(payLoad) {
    return await auditService.fetchAuditData(payLoad);
}