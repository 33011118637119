import moment from "moment";
import { useTranslation } from "./i18n";
import { userInfo } from "../apis/apiUtitlity";
//below function will also handle date string recieved in short format eg: dd/mm/yyyy 
export function getFormattedDateFromString(dateWithTime: string) {
  return moment.parseZone(dateWithTime).format("L");
}
export function getDateInUtcFromString(dateWithTime: string) {
  var date = new Date(dateWithTime);
  var userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
}
export function useMoment() {
  const [t, i18n] = useTranslation();
  moment.locale(i18n.language);
  return [moment];
}
export function getDate() {
  return moment().format("YYYY-MM-DD");
}
export function useLocalisedDateJs() {
  const [t, i18n] = useTranslation();
  let language = i18n.language;
  language =
    language && language.match("_") ? language.replace("_", "-") : language;
  const getLocalisedDateFromString = (inDate: Date | number) => {
    let correctedDate = new Intl.DateTimeFormat(language).format(inDate);
    return correctedDate;
  };
  return [getLocalisedDateFromString];
}

export function convertDateinDbExpectedFormat(inDate: Date) {
  let lang1 =
    userInfo.language && userInfo.language.match("_")
      ? userInfo.language.replace("_", "-")
      : userInfo.language
      ? userInfo.language
      : "en-GB";

  // console.log('langauge in function ',lang1)
  //  lang1=language && language.match('_')? language.replace('_','-'):language;
  return new Intl.DateTimeFormat(lang1).format(inDate);
}
export function getDateFormatFromLocale() {
  let lang1 =
    userInfo.language && userInfo.language.match("_")
      ? userInfo.language.replace("_", "-")
      : userInfo.language
      ? userInfo.language
      : "en-GB";
  moment.locale(lang1);
  var localeData = moment.localeData();
  return localeData.longDateFormat("L");
}

export function isValidDateString(txtDate)
{
    let currVal = txtDate;
    if(currVal === '')
        return false; 
        let dateFormat=getDateFormatString();   
        let rxDatePattern= /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/; //Declare Regex
        let dtArray = currVal.match(rxDatePattern); // is format OK?
    if (dtArray == null) 
        return false;    
    //Checks for mm/dd/yyyy format.
    let formatparts=dateFormat.split('/');
    let dtMonth = formatparts&& formatparts[0]&&'MM'===formatparts[0]? dtArray[1]:dtArray[3];
    let dtDay= formatparts&& formatparts[0]&&'MM'===formatparts[0]? dtArray[3]:dtArray[1];
    let dtYear = dtArray[5]; 
    // let dtMonth = dtArray[1];
    // let dtDay= dtArray[3];
    // let dtYear = dtArray[5];       
    
    if (dtMonth < 1 || dtMonth > 12) 
        return false;
    else if (dtDay < 1 || dtDay> 31) 
        return false;
    else if ((dtMonth===4 || dtMonth===6 || dtMonth===9 || dtMonth===11) && dtDay ===31) 
        return false;
    else if (dtMonth === 2) 
    {
        var isleap = (dtYear % 4 === 0 && (dtYear % 100 !== 0 || dtYear % 400 === 0));
        if (dtDay> 29 || (dtDay ===29 && !isleap)) 
                return false;
    }
    return true;

}
export function validateDate(dateStr: string, isPastDateAllowed: boolean) {
  isPastDateAllowed = isPastDateAllowed ? isPastDateAllowed : false;
  let time= moment(dateStr);
  try {
    if (dateStr && dateStr.length >= 10) {
        var patternDDMM = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
        var patternYYMM = /^([0-9]{4})\/([0-9]{2})\/([0-9]{2})$/;

      let dateParts: Array<string> = dateStr.split("/");
      if (dateParts && dateParts.length === 3) {
        //length is >=10  and  3 parts separatred by / are available
        var userDate = null;
        //checking  if first part is year or date/month
        if(patternDDMM.test(dateStr)){
        // if (dateParts[0].length === 2) {
        //   if (dateParts[1].length === 2 && dateParts[2].length === 4) {
            //checking which part is month
            if (
              Number.parseInt(dateParts[0]) <= 12 &&
              Number.parseInt(dateParts[1]) >= 12
            ) {
              userDate = new Date(
                Number.parseInt(dateParts[0]),
                Number.parseInt(dateParts[1]) - 1,
                Number.parseInt(dateParts[2])
              );
            } else {
              userDate = new Date(
                Number.parseInt(dateParts[1]),
                Number.parseInt(dateParts[0]) - 1,
                Number.parseInt(dateParts[2])
              );
            }
        }
        //   } else {
        //     return "Invalid";
        //   }
        // }
        //when first part is year
        else if(patternYYMM.test(dateStr)){
        // if (dateParts[0].length === 4) {
        //   if (dateParts[1].length === 2 && dateParts[2].length === 2) {
            if (Number.parseInt(dateParts[1]) > 12 && Number.parseInt(dateParts[2])>=12) {
              userDate = new Date(
                Number.parseInt(dateParts[2]),
                Number.parseInt(dateParts[1]) - 1,
                Number.parseInt(dateParts[0])
              );
            } else {
              userDate = new Date(
                Number.parseInt(dateParts[1]),
                Number.parseInt(dateParts[2]) - 1,
                Number.parseInt(dateParts[0])
              );
            }
          } else {
            return "Invalid";
          }
        // }
        if (
          !isPastDateAllowed &&
          userDate &&
          userDate.getTime() < new Date().getTime()
        ) {
          return "Invalid";
        }
      } else {
        // /is missing
        return "Invalid";
      }
    } else {
      // min lenght not met
      return "Invalid";
    }
  } catch (err) {
    return "Invalid";
  }
  return null;
}
export function  getDateFormatString() {
  const formatObj = new Intl.DateTimeFormat(    userInfo.language && userInfo.language.match("_")
  ? userInfo.language.replace("_", "-")
  : userInfo.language
  ? userInfo.language
  : "en-US").formatToParts(new Date());

  return formatObj
    .map(obj => {
      switch (obj.type) {
        case "day":
          return "dd";
        case "month":
          return "MM";
        case "year":
          return "yyyy";
        default:
          return obj.value;
      }
    })
    .join("");
}
