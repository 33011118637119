import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classes from "../styles/DatePickerMedium.module.scss";
import { useTranslation } from "../../modules/utils";
import captions from "../../commonLabels.json";
import { getDateFormatString, isValidDateString } from "../utils/date";
type TDatePickerMedium = {
  readOnly?: true | false;
  disabled?: true | false;
  minDate?: Date;
  startDate?: Date;
  handleChange: Function;
  selectedDate?: Date | string;
  objectId?: string | number;
  style?: React.CSSProperties;
  fieldLabel?: string;
};
let style = {
  width: "inherit",
  outline: "none",
  border: "1px solid #000000",
  borderRadius: 0,
  minHeight: "48px",
};
export default function DatePickerMedium(props: TDatePickerMedium) {
  const [t] = useTranslation();
  const [isValid, setIsValid] = useState(true);
  const [isValidFormat, setIsValidFormat] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  let selectedDate: Date | string = null;
  let dateFormat = getDateFormatString();
  let style1 = {};
  const handleChangeRaw = (value: string) => {
    selectedDate = null;
    if (!value) {
      setIsExpired(false);
      setIsValid(false);
    }
    if (value && value.length > 4 && !value.match("/")) {
      setIsValid(false);
    } else {
      selectedDate = null;
      setIsValid(true);
    }
    if (value && value.length >= 10) {
      var patternDDMM = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
      var patternYYMM = /^([0-9]{4})\/([0-9]{2})\/([0-9]{2})$/;
      if (!patternDDMM.test(value) && !patternYYMM.test(value)) {
        setIsValid(false);
      } else {
        if (isValidDateString(value)) {
          let dateParts = value.split('/');
          let dateFormatParts = dateFormat.split('/');
          let day = dateParts && dateParts[0] && 'dd' === dateFormatParts[0] ? Number.parseInt(dateParts[0]) : Number.parseInt(dateParts[1]);
          let month = dateParts && dateParts[0] && 'dd' === dateFormatParts[0] ? Number.parseInt(dateParts[1]) : Number.parseInt(dateParts[0]);
          let year = dateParts && dateParts.length >= 2 ? Number.parseInt(dateParts[2]) : new Date().getFullYear();
          selectedDate = new Date(year, month && month > 0 ? month - 1 : month, day);
          if (!'Invalid'.match(selectedDate.toString())) {
            setIsValidFormat(true);
            let selectedDateWithoutTime = new Date(
              selectedDate.getFullYear(),
              selectedDate.getMonth(),
              selectedDate.getDate()
            );
            let newDate = new Date();
            let currentDateWithoutTime = new Date(
              newDate.getFullYear(),
              newDate.getMonth(),
              newDate.getDate()
            );
            if (
              selectedDateWithoutTime.getTime() < currentDateWithoutTime.getTime()
            ) {
              setIsExpired(true);
            } else {
              setIsExpired(false);
              selectedDate = null;
              setIsValid(true);
            }
          }
          else {
            setIsValidFormat(false);
          }
        } else {
          setIsValid(false);
        }
      }
    }
  };
  const handleOnChange = (selValue) => {
    if (selValue) {
      setIsValid(true);
      setIsExpired(false);
    }
    // if (isExpired||!isValid)  {
    //   selValue = selectedDate;
    // }
    if (props.handleChange) {
      props.handleChange(selValue, props.objectId);
    }
  };
  let keyIndex = props.objectId ? props.objectId : "1";
  let style2 = !isValid ? style1 : style;

  let defaultClass = !isValid || !isValidFormat || isExpired ? classes["red-border"] : classes["date-picker"];
  let errorLabel = null;
  let prefix = props.fieldLabel ? props.fieldLabel : "Date ";
  errorLabel = isValidFormat ? (!isValid ? (
    <div>
      <span style={{ color: "red", wordWrap: "break-word", position: "relative" }}>
        {`${prefix} ${t(captions.common["err-date-must-be-valid"])}`}
      </span>
    </div>
  ) : isExpired ? (
    <div>
      <span style={{ color: "red", wordWrap: "break-word", zIndex: 10, position: "fixed" }}>
        {`${t(captions.common["err-date-must-not-expired"])}`}
      </span>
    </div>
  ) : null) : (<div>
    <span style={{ color: "red", wordWrap: "break-word", zIndex: 10, position: "fixed" }}>
      {`Date must be in ${dateFormat}`}
    </span>
  </div>);
  selectedDate = selectedDate
    ? selectedDate
    : props.selectedDate
      ? new Date(props.selectedDate)
      : null;
  return (
    <React.Fragment>
      <div className={!isValid||!isValidFormat||isExpired  ? classes['date-picker-error']:classes['date-picker']} >
        <DatePicker
          key={keyIndex}
          style={props.style ? { ...style2, ...props.style } : { ...style2 }}
          selected={selectedDate}
          onChange={handleOnChange}
          readOnly={props.readOnly ? props.readOnly : false}
          disabled={props.disabled ? props.disabled : props.readOnly}
          minDate={props.minDate ? props.minDate : ''}
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={5}
          scrollableYearDropdown
          onChangeRaw={(event) => handleChangeRaw(event.target.value)}
          strictParsing
          className={defaultClass}
          dateFormat={dateFormat}
          todayButton={t(captions.common["lbl-datepicker-today"])}
          popperPlacement="top-end"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "5px, 15px"
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport"
            }
          }}
        >

        </DatePicker>
        {errorLabel}
      </div>

    </React.Fragment>
  );
}
