import React, { useEffect, useState } from 'react'
import { arrowLeft } from '..'
import ButtonMedium from '../../../../../modules/components/ButtonMedium'
import Label from '../../../../../modules/components/Label';
import { RateAuditData, services, useTranslation, captions, RateAuditFilter, AppData, useMoment } from '../index';
import { fetchSaConfiguredRateTypes } from '../services/rateService'
interface IAudtiEntity {
    entityId: string
    objectId: string
    auditDate: string
    auditTimeStamp: string
    userName: string
    entityName: string
}
interface IDataList extends Array<IAudtiEntity> { }
interface IResponseAuditApi {
    Errors?: [],
    Result: | 'Success' | 'Failure'
    auditList?: IDataList
}

export default function RateAudit(props) {
    const [metaData] = useState(props.location.state && props.location.state.rowData ? props.location.state.rowData : []);
    let tempSaRateType: [];
    let pricingMatrixId = metaData !== null ? metaData.pricingMatrixId : '';
    const [selectedProductLineDescription] = useState(metaData !== null ? metaData.productLineDescription : '');
    const [entityId] = useState(`${pricingMatrixId}-${AppData.serviceProviderId}`);
    const [selectedDescription, setSelectedDescription] = useState('');
    const [auditList, setAuditList] = useState([]);
    const [t] = useTranslation();
    const [saRateTypes, setSaRateTypes] = useState(tempSaRateType);
    const [selectedRateTypes, setSelectedRateTypes] = useState(tempSaRateType);
    const [containsSearch, SetContainsSearch] = useState(false);
    let [moment] = useMoment();
    async function descriptionSelectChangeHandler(e) {
        e.preventDefault();
        if (e && e.target) {
            let value = e.target.value;
            setSelectedDescription(value);
            fetchAuditData(value, selectedRateTypes);
        }

    }
    async function rateTypeCheckBoxHandler(e) {
        let propertykey = `${e.target.id}`;
        if (selectedRateTypes.hasOwnProperty(propertykey)) {
            let oldValue: any = selectedRateTypes[propertykey];
            let newValue = oldValue ? false : true;
            let tempObj = { ...selectedRateTypes };
            tempObj[propertykey] = newValue;
            setSelectedRateTypes({ ...tempObj });
            fetchAuditData(selectedDescription, tempObj);
        }
    }
    function createFieldIdString(selectedDescription, selectedRateTypes) {
        let fieldString = '';
        if (selectedDescription && selectedDescription.length > 0) {
            if (selectedRateTypes) {
                let isRateTypeSelected = false;
                if (Array.isArray(selectedRateTypes) && selectedRateTypes.length > 0) {
                    selectedRateTypes.forEach((item, index) => {
                        fieldString = fieldString + `${item}-${selectedDescription}`;
                    });
                }
                else {
                    // eslint-disable-next-line
                    for (let key in selectedRateTypes) {
                        if (selectedRateTypes[key] && selectedRateTypes[key] === true) {
                            isRateTypeSelected = true;
                            break;
                        }
                    }
                }
                if (isRateTypeSelected) {
                    for (let key in selectedRateTypes) {
                        if (selectedRateTypes[key] && selectedRateTypes[key] === true) {
                            if (fieldString && fieldString.length > 0) {
                                fieldString = fieldString + `,`;
                            }
                            fieldString = fieldString + `${key}-${selectedDescription}`;
                        }
                    }
                }
                else {
                    return selectedDescription;
                }

            }
            return fieldString;
        }
        if ((null == selectedDescription || undefined === selectedDescription || (null != selectedDescription && selectedDescription.length === 0)) && selectedRateTypes) {
            for (let key in selectedRateTypes) {
                if (selectedRateTypes[key] && selectedRateTypes[key] === true) {
                    if (fieldString && fieldString.length > 0) {
                        fieldString = fieldString + `,`;
                    }
                    fieldString = fieldString + `${key}`;

                }
            }
        }

        return fieldString;
    }
    async function loadAuditList(selectedDescription, selectedRateTypes) {
        //delyed api call to let user object update finished
        // let entityId=
        let initPayLoad = {
            entityId: entityId,
            entityType: 'com.servicebench.cribbage.model.claims.pricing.PricingMatrix',
            containsSearch: containsSearch,
            auditDateFrom: moment().subtract(365, "days").format('YYYY-MM-DD'),
            auditDateTo: moment().add(1, "days").format('YYYY-MM-DD')
        }
        let payLoad = null;
        let fieldIdString = createFieldIdString(selectedDescription, selectedRateTypes);
        if (fieldIdString && fieldIdString.length > 0) {
            payLoad = { ...initPayLoad, containsSearch: true, fieldId: fieldIdString }
        }

        return services.auditService.fetchAuditData(payLoad ? payLoad : initPayLoad);
    }
    async function fetchAuditData(selectedDescription, selectedRateTypes) {
        await loadAuditList(selectedDescription, selectedRateTypes).then(
            (data: IResponseAuditApi) => {
                data && data.auditList
                    ? setAuditList(data.auditList) : setAuditList([]);
            }
        );
    }

    useEffect(() => {
        async function fetchSaRateTypes() {
            let codeDescriptionList = fetchSaConfiguredRateTypes();
            codeDescriptionList.then((data) => {
                let convertedObject = services.convertObjectToLabelValueType(data, 'description', 'codeValue');
                if (convertedObject && convertedObject.length > 0) {
                    let obj: [] = [];
                    convertedObject.forEach(item => {
                        let key = `${item.value}`;
                        obj[key] = false;
                    });
                    setSelectedRateTypes(obj);
                    setSaRateTypes(convertedObject);
                }
            }
            )
        }
        fetchSaRateTypes();
        fetchAuditData(null, null);
    }, [])

    return (
        <React.Fragment>
            <div className='row' >
                <div className='col-6 pl-0'>
                    <img
                        alt="ServiceBench"
                        title="ServiceBench (logo)"
                        aria-hidden="true"
                        src={arrowLeft}
                        onClick={() => { props.history.goBack() }}
                        style={{ paddingTop: '1%' }}
                    />
                    <ButtonMedium style={{ minWidth: 0, minHeight: '20px', paddingTop: '0%' }} labelStyle={{ paddingTop: '0%', marginTop: '0%' }} captionSize="md" variant='link' label={t(captions["lbl-link-back-rate-maintenance"])} onClick={(e) => { props.history.goBack(); }} />
                </div>
            </div>
            <div className='row pl-3' >
                <Label size="title" label={selectedProductLineDescription} style={{ marginTop: 0 }} />
            </div>
            <RateAuditFilter saRateTypes={saRateTypes} selectedRateTypes={selectedRateTypes} descriptionChangeHandler={(e) => { descriptionSelectChangeHandler(e) }} selectedDescription={selectedDescription} rateTypeCheckBoxChange={rateTypeCheckBoxHandler} ></RateAuditFilter>
            {auditList && auditList.length > 0 ? <RateAuditData auditDataList={auditList} /> : <React.Fragment><div className="row justify-content-center " style={{ border: '1px solid beige', marginTop: "5rem" }}> <Label size="xlg" style={{ marginTop: 0 }} label={t(captions['txt-no-data-available'])}></Label></div></React.Fragment>}
        </React.Fragment>
    )
}

