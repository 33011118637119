import React, { useEffect, useState } from 'react'
import { Checkbox } from '..'
import Label from '../../../../../modules/components/Label'
import Select from '../../../../../modules/components/Select';
import { useTranslation, captions, cssRateSearch, services } from '../index'
import { fetchSaConfiguredRateAmountTypes } from '../services/rateService';
interface Iprops {
    rateTypeCheckBoxChange: Function,
    selectedDescription: string,
    descriptionChangeHandler: Function
    saRateTypes: [],
    selectedRateTypes: []
}
function RateAuditFilter(props: Iprops) {
    const [t] = useTranslation();
    const [descriptions, setDescriptions] = useState([]);
    let saRateTypes = props && props.saRateTypes ? props.saRateTypes : [];

    useEffect(() => {
        async function fetchAmountTypesOnLoad() {
            let codeDescriptionList = fetchSaConfiguredRateAmountTypes();
            codeDescriptionList.then((data) => {
                let convertedObject = services.convertObjectToLabelValueType(data, 'description', 'codeValue');
                if (convertedObject && convertedObject.length > 0) {
                    setDescriptions(convertedObject);
                }
            }
            )
        }
        fetchAmountTypesOnLoad();
    }, []);

    const RateTypeCheckBoxes = () => {
        return (
            (<React.Fragment>
                {saRateTypes ? saRateTypes.map((item, index: number) => {
                    let rateTypeKey = `${item.value}`;
                    let label;
                    // we are iterarting over a dynamic list and are trying to fetch the translation 
                    // so there might be a case when db has new entries by transaltions are not updated then function t used to get translation would thorow error thus we are handling this error here
                    // and when error would be thrown then we are going to  put the description availble  in st_code_Description table  
                    try {
                        label = t(captions[`lbl-${item.value}`])
                    }
                    catch (err) {
                        label = `${item.label}`
                        console.log("error caused in RateAuditFilter.tsx while getting tranlsation for rate type check boxes for ", err);
                    }
                    return (
                        <div className="col-sm" key={`div-${rateTypeKey}`} >
                            <Checkbox label={label}
                                isChecked={props.selectedRateTypes[rateTypeKey]}
                                onChange={(e) => { props.rateTypeCheckBoxChange(e) }}
                                className={cssRateSearch['rate-checkbox']}
                                id={item.value}
                                key={rateTypeKey}
                            />
                        </div>
                    );
                }) : <React.Fragment />}
            </React.Fragment>)
        )
    }
    const AuditTableFilters = () => {
        return (
            (<React.Fragment>
                {saRateTypes ?
                    <div className='row' >
                        <div className='row col-7' >
                            <div className="col-sm" >
                                <Label label={t(captions["lbl-rate-type"])} size="xlg"></Label>
                            </div>
                            <RateTypeCheckBoxes />
                        </div>
                        <div className='row col-5' >
                            <div className="col-sm" >
                                <Label label={t(captions["lbl-description"])} size="xlg"></Label>
                            </div>
                            <div className="col-sm" >
                                <Select value={props.selectedDescription} key="Description"
                                    onChange={(e) => { props.descriptionChangeHandler(e) }}
                                    options={descriptions}
                                />
                            </div>
                        </div>
                    </div> : <React.Fragment />
                }
            </React.Fragment>
            )
        )
    }
    return (
        <React.Fragment>
           <AuditTableFilters/>
        </React.Fragment>
    )
}

export default RateAuditFilter

