import React from 'react';
import Input from '../../../modules/components/Input';
import DatePickerMedium from '../../../modules/components/DatePickerMedium';
import ButtonMedium from '../../../modules/components/ButtonMedium';
import * as certTypes from './certTypes';
import { useTranslation } from '../../../modules/utils';
import captions from './captionsSpCertifications.json';
import classes from '../../../modules/styles/CertificationsTable.module.scss'
import { getDateInUtcFromString, convertDateinDbExpectedFormat } from '../../../modules/utils/date';
type TProps = {
    tableData: certTypes.TCertifications,
    tableClassName?: string,
    customStyle?: [],
    handleDelete: Function | any,
    isEditCalled: | true | false,
    dateChangeHandler: Function,
    inputChangeHandler: Function
}
type THeaders = {
    name: string,
    type: string,
    refNumber: string,
    expDate: string
}

    const formatDateStringByUserLocale = (inDate: string) => {
        let date = convertDateinDbExpectedFormat(new Date(inDate));
        return date;
    }
    const TBody = ({ props, t,headers}) => {
        let style1 = {
            maxHeight: '72px', fontFamily: 'Apercu Pro', fontSize: 16, color: '#000000', border: '', paddingTop: ''
        }

        
        var trs = props.tableData.map((item: certTypes.TCertification, index: number) => {
            let styles = style1; if (index === 0) {
                styles = {...style1, border: index === 0 ? 'none' : '' };
            }
            let isEdit = props.isEditCalled
            let date = isEdit && item.expirationDate ? getDateInUtcFromString(item.expirationDate) : null;
            let objectId = item.certificationTypeCode ? `${item.certificationTypeCode}-${item.certificationId}` : `${item.certificationId}`;
            let requiredExpDate=(<React.Fragment>
               {isEdit&&item.checkExpiration && (item.checkExpiration==='1' ||item.checkExpiration===1||item.checkExpiration===true ||item.checkExpiration==='true') &&!date ?<span style={{ color: 'red', wordWrap:'break-word' }}>{t(captions["err-req-exp-date"])}</span> :''}
               </React.Fragment>)
            
            return <tr key={index} data-item={item} style={{ maxHeight: '72px', overflowY: 'hidden' }} >
                <td style={styles}>
                    {item.certificationType}
                </td>
                <td style={styles}>
                    {item.certificationName}
                </td>
                {isEdit ?
                    <React.Fragment>
                        <td style={styles}>
                            <Input onChange={props.inputChangeHandler} objectId={objectId} value={item.certificationNumber}></Input></td>
                        <td style={{...styles,maxWidth:'100px'}} ><DatePickerMedium selectedDate={date} objectId={objectId} fieldLabel={headers.expDate}
                            minDate={new Date()}
                            handleChange={props.dateChangeHandler}/>
                            {requiredExpDate}</td>
                        <td style={styles}><ButtonMedium variant='link' labelColor='#DB1B1B' label={t(captions["lbl-btn-delete"])} onClick={() => props.handleDelete(item)}
                            style={{ maxHeight: '', marginLeft: '1%', fontSize: 12 }}></ButtonMedium></td>
                    </React.Fragment> :
                    <React.Fragment>
                        <td style={styles}>{item.certificationNumber}</td>
                        <td style={styles}>{item.expirationDate ? formatDateStringByUserLocale(item.expirationDate) : null}</td>
                    </React.Fragment>
                }
               
            </tr>;
        });
        return (
            <tbody style={{
                maxHeight: '200', overflowY: 'scroll'
            }}>
                {trs}
            </tbody>
        );
    }
    const THead = ({ headers }) => {
        const style1 = { paddingBottom: 2, fontFamily: 'Apercu Pro', fontSize: 12, border: 'none' }
        return (<thead><tr>
            <th style={style1} className={classes['th-td']}>{headers.type}</th>
            <th style={style1} className={classes['th-td']}>{headers.name}</th>
            <th style={style1} className={classes['th-td']}>{headers.refNumber}</th>
            <th style={style1} className={classes['th-td']}>{headers.expDate}</th>
        </tr></thead>)
    }
    const table = (customStyle, props, headers: THeaders, t) => {
        var tBody = props.tableData ? <TBody props={props} t={t}  headers={headers}/> : '';
        let tHead = (props.tableData ? <THead headers={headers} /> : '')
        return (
            <div style={customStyle}>
                <table className={props.tableClassName ? props.tableClassName : "table"} >
                    {tHead}
                    {tBody}
                </table>
            </div>
        )
    }
    export default function CertificationsTable(props: TProps) {
        const [t] = useTranslation();
        let headers: THeaders = {
            type: t(captions["lbl-type"]),
            name: t(captions["lbl-name"]),
            refNumber: t(captions["lbl-ref-number"]),
            expDate: t(captions["lbl-exp-date"])
        }
        // let captionDelete=t(captions["lbl-btn-delete"].id,captions["lbl-btn-delete"].default);
        let defaultStyle = {
            marginLeft: '2%',
            lineHeight: '1',
            width: '100%'
        }
        let style = props.customStyle ? props.customStyle : defaultStyle;
    return (<React.Fragment>
        {props.tableData ? table(style, props, headers, t) : ''}
    </React.Fragment>);
}
