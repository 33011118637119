import React, { memo } from 'react';
import Label from '../Label';

interface Iprops {
  className?: string,
  label: string,
  isChecked: true | false,
  onChange: Function
  id?: string,
  keyField?:string
}
const Checkbox = (props: Iprops) => (
  <React.Fragment>
          
    <div className='row' style={{height:'100%',alignItems:'center'}}  >
        <input
          type="checkbox"
          value={props.label}
          checked={props.isChecked}
          onChange={(e) => {
            // e.preventDefault();
            props.onChange(e)
          }
          }
          id={props.id}
          key={props.keyField?props.keyField:props.id}
          style={{ maxHeight: '24px !important', minHeight:'24px', width: '24px' }}
        />
       <Label size="lg" label={ props.label} style={{marginLeft:'8%'}}></Label>
    </div>
  </React.Fragment>
);

export default memo(Checkbox);