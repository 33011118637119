import React, { useState } from 'react';
import classes from '../rate-search/RateSearch.module.scss';
import {rateTypes,AutoCompleteInput,useTranslation,captions, Label, Post, AppData,Radiobutton,isEmpty,loadProductLines} from '../index'


const config = {
    maxLimt: 10,
    minInput: 3,
    entityType: 'com.servicebench.cribbage.model.common.ServiceProviderCert',
};
const Status = {
    active: "active",
    inactive: "inactive",
};
const RateType = {
    fixed: "FIXED",
    flat: "FLATRATE",
    hourly: "HOURLY"
};
const NewRate = ({
    rateType,
    statusRadioChange,
    rateTypeRadioChange,
    captureProductLineSelect,
    isEditRate,
    detailMetaData,
    selectedProductLine,
    selectedStatus,
    selectedRateType,
    isProductLineSelected,
    prodLineError
}) => {
    const [t] = useTranslation();
    let searchFilter = {
        productLineEntered: '',
        selectedProductLine: null
    }
    const [state, setState] = useState(searchFilter);
    async function onProductLineInputChange(input: string, cb: Function) {
        setState({ ...state, productLineEntered: input });
        if (input) {
            if (input.length >= config.minInput) {
                let response: rateTypes.TResponseDataType = await Post(loadProductLines.subUri, {}, {
                    serviceAdministratorId: AppData.serviceAdministratorId, productLineDescription: input
                });
                if (response && response.Result && response.Result === 'Success' && response.productLineList && response.productLineList.length > 0) {
                    let formattedObject = convertObjectToGenericType(response.productLineList, 'productLineDescription', 'productlineCode', null)
                    cb(formattedObject);
                }
                else {
                    cb(null);
                }
            }

        } else if (isEmpty(input)) {
            captureProductLineSelect(null);
        }
    }
    function convertObjectToGenericType(object: any, labelKey: string, valueKey: string, inputType: string) {
        if (object && object.length > 0) {
            let newObj: any = [];
            object.forEach(function (item: any, index) {
                let newItem = {
                    label: item[labelKey],
                    value: item[valueKey],
                    data: item
                };
                newObj.push(newItem);
            });
            return newObj;
        }
        else { return {} }
    }
    function onProductLineSelect(inputCode, selectedOption) {
        if (selectedOption && selectedOption.value) {
            captureProductLineSelect(selectedOption);
            setState({ ...state, productLineEntered: selectedOption.label, selectedProductLine: selectedOption.value })
        }
    }

    return (
        <React.Fragment>
            <div className="row ">
                <div className="col-7">
                    <Label label={t(captions["lbl-prodLine"])} size="lg" ></Label>
                    <AutoCompleteInput
                        maxLimitReached={false}
                        minInput={config.minInput}
                        maxLimit={config.maxLimt}
                        code=""
                        showSuggestions={true}
                        captureUserSelections={onProductLineSelect}
                        handleInputChange={onProductLineInputChange}
                        readOnlyTrue={false}
                        value={selectedProductLine?selectedProductLine:state.productLineEntered}
                        disabled={isEditRate ? true : false}
                        isForceEmpty={true}
                        t={t}
                        placeholder={t(captions["lbl-place-holder-product-line"])}
                    />
                </div>
                <div className="col-5"  >
                    <div className="row" style={{ alignItems: 'flex-end', height: '100%' }}>
                        <div className="col-2" >
                            <Label label={t(captions["lbl-status"])} size="xlg"></Label>
                        </div>
                        <div className="col-8" style={{ alignItems: 'flex-end', height: '40%' }} >
                            <div className="row" >
                                <div className="col-5" >
                                    <Radiobutton label={t(captions["lbl-active"])}
                                        // isChecked={status}
                                        onChange={statusRadioChange}
                                        id={Status.active}
                                        name={'status'}
                                        selectedValue={selectedStatus}

                                    /></div>
                                <div className="col-5" >
                                    <Radiobutton label={t(captions["lbl-inactive"])}
                                        // isChecked={!status}
                                        onChange={statusRadioChange}
                                        id={Status.inactive}
                                        name={'status'}
                                        selectedValue={selectedStatus}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" style={{ height: 18, marginTop: '0.3%' }}>
                <div className="col-6" id="prodLineErrorId"> 
                    {!isProductLineSelected   ? <span style={{ color: 'red' }}>{prodLineError}</span> : <React.Fragment />}
                </div >
            </div>
            <div className="row pl-3">
                <Label label={t(captions["lbl-rates"])} size="xlg" style={{ marginBottom: 'none', marginTop: '0px' }}></Label>
            </div>
            <div className="row pl-3">
                <Label label={t(captions["lbl-rateType"])} size="lg" style={{ marginBottom: 'none', marginTop: '0px' }}></Label>
            </div>
            <div className="row col-6">
                <div className='col-12' >
                    <Radiobutton label={t(captions["lbl-fixed"])}
                        // isChecked={fixedChecked}
                        selectedValue={selectedRateType}
                        onChange={rateTypeRadioChange}
                        className={classes["radiobutton"]}
                        id={RateType.fixed}
                        name={'type'}
                    />
                    <Radiobutton label={t(captions["lbl-flat"])}
                     selectedValue={selectedRateType}
                        onChange={rateTypeRadioChange}
                        className={classes["radiobutton"]}
                        id={RateType.flat}
                        name={'type'}
                    />
                    {/* </div>
                    <div className='col-3'> */}
                    <Radiobutton label={t(captions["lbl-hourly"])}
                    selectedValue={selectedRateType}
                        onChange={rateTypeRadioChange}
                        className={classes["radiobutton"]}
                        id={RateType.hourly}
                        name={'type'}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default NewRate;
