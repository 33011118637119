import React from 'react'

const style = (status?: 'warning' | 'info' | 'success') => ({
  padding: 48,
  fontSize: 36,
  backgroundColor: status === 'info' ? '#e3e3e3' : status === 'success' ? '#37E7A7' : status === 'warning' ? 'Yellow' : '#000',
  // color: !status || status === 'info' ? '#fff' : '#000'

})

interface PropsNotification {
  status?: 'warning' | 'info' | 'success'
  text: string;
  text2?: string;
  location?: { state: { text: string, text2?: string,style?:'warning' | 'info' | 'success' } }
}

export const Notification = (props: PropsNotification) => {
  let style1 = props.status;
  let text = props.text;
  let text2 = props.text2;
  if (props.location && props.location.state) {
    text = props.location.state.text;
    text2 = props.location.state.text2;
    style1=props.location.state.style
  }
  const line2 = text2 ? (<div><span style={{ fontSize: 20 }}>{text2}</span></div>) : <React.Fragment />
  return (
    <div className='Center' style={{ marginTop: '10%' }}>
      <div style={style(style1)}>
        <div>{text}</div>
        {line2}
      </div>
    </div>
  )
}

