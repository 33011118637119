import React from 'react';
import Input from '../../../modules/components/Input';
import DatePickerMedium from '../../../modules/components/DatePickerMedium';
import ButtonMedium from '../../../modules/components/ButtonMedium';
import * as InsuranceDataTypes from './insuranceDataTypes';
import classes from './Insurance.module.scss';

import { useTranslation } from '../../../modules/utils';
import captions from './captionsSpInsurance.json';
import {getDateInUtcFromString, convertDateinDbExpectedFormat } from '../../../modules/utils/date';

type TProps = {
    tableData: InsuranceDataTypes.TInsurances,
    tableClassName?: string,
    customStyle?: [],
    handleDelete: Function | any,
    isEditCalled: | true | false,
    dateChangeHandler: Function,
    inputChangeHandler: Function,
    errors: { type: string, limit: string, date: string, dateTableErrorMsg: string, limitTableErrorMsg: string, errorInTable: boolean | false | true, insuranceTypeCode: number | string }

}
type THeaders = {
    type: string,
    limit: string,
    expDate: string
}

const TBody = ({ props, t,headers }) => {
    const style1 = {
        maxHeight: '72px', fontFamily: 'Apercu Pro', fontSize: 16, color: '#000000', border: '', paddingTop: ''
    }
    var trs = props.tableData.map((item: InsuranceDataTypes.TInsurance, index:number) => {
        let styles = style1; if (index === 0) {
            styles = { ...style1, border: index === 0 ? 'none' : '' };
        }
        let isEdit = props.isEditCalled;
        let date = isEdit && item.expirationDate ? getDateInUtcFromString(item.expirationDate) : null;
        let objectId = item.insuranceTypeCode;
        let flag= (item.checkExpiration===1 || item.checkExpiration === '1') && !date;
        let requiredExpDate = (<React.Fragment>
            { flag ? <span style={{ color: 'red', wordWrap: 'break-word' }}>{t(captions["err-req-exp"])}</span> : ''}
        </React.Fragment>)
        let requiredPolicyLimit = (<React.Fragment>
            {isEdit && !item.policyLimit ? <span style={{ color: 'red', wordWrap: 'break-word' }}>{t(captions["err-req-limit"])}</span> : ''}
        </React.Fragment>)
        const regEx = /^[0-9\b]+$/;
        let formatPolicyLimit = (<React.Fragment>
            {(isEdit && item.policyLimit && (!regEx.test(item.policyLimit.toString()) )) ?
                <span style={{ color: 'red', wordWrap: 'break-word' }}>{t(captions["err-limit-numeric"])}</span> : ''}
        </React.Fragment>)
        let numericPolicyLimit = (<React.Fragment>
            {(isEdit && item.policyLimit && ( item.policyLimit > 10000000 || item.policyLimit <1)) ?
                <span style={{ color: 'red', wordWrap: 'break-word' }}>{t(captions["err-limit-numbers"])}</span> : ''}
        </React.Fragment>)

        return <tr key={index} data-item={item} style={styles} >
            <td style={styles}>
                {item.insuranceTypeDescription}
            </td>
            {isEdit ?
                <React.Fragment>
                    <td style={{ ...styles, maxWidth: '100px' }} >
                        <Input objectId={objectId} value={item.policyLimit} onChange={props.inputChangeHandler} ></Input>
                        {/* {<div style={{ color: 'red' }} > {item.limitErrorMsg} </div>} */}
                        {requiredPolicyLimit}{formatPolicyLimit}{numericPolicyLimit}
                    </td>
                    <td style={{ ...styles, maxWidth: '100px' }} ><DatePickerMedium selectedDate={date} objectId={objectId} fieldLabel={headers.expDate}
                        minDate={new Date()}
                        handleChange={props.dateChangeHandler} />
                        {requiredExpDate}
                        {/* { <div style={{color:'red'}} > {item.dateErrorMsg} </div>} */}
                        {/* { props.errors.insurancecodetype==objectId && props.errors.datehandler && <div> {props.errors.dateerrormessage} </div>} */}
                    </td>
                    <td style={styles}>
                        <ButtonMedium labelColor='red' label={t(captions["lbl-btn-delete"])} onClick={() => props.handleDelete(item)}
                            variant='link' style={{ maxHeight: '', marginLeft: '1%,min' }}></ButtonMedium>
                    </td>
                </React.Fragment>
                :
                <React.Fragment>
                    <td style={styles}>
                        {item.policyLimit}
                    </td>
                    <td style={styles}>
                        {item.expirationDate ? convertDateinDbExpectedFormat(new Date(item.expirationDate)) : null}
                    </td>

                </React.Fragment>
            }
        </tr>
            
    });
    return (
        <tbody>
            {trs}
        </tbody>
    );
}
const THead = ({ headers }) => {
    const style1 = { paddingBottom: 2, fontFamily: 'Apercu Pro', fontSize: 12, border: 'none' }

    return (<thead><tr>
        <th style={style1} className={classes['th-td']} >{headers.type}</th>
        <th style={style1} className={classes['th-td']} >{headers.limit}</th>
        <th style={style1} className={classes['th-td']} >{headers.expDate}</th>
    </tr></thead>)
}
const table = (customStyle, props, headers: THeaders, t) => {
    var tBody = props.tableData ? <TBody props={props} t={t} headers={headers} /> : '';
    let tHead = (props.tableData ? <THead headers={headers} /> : '')
    return (
        <div style={customStyle}>
            <table className={props.tableClassName ? props.tableClassName : "table"} >
                {tHead}
                {tBody}
            </table>
        </div>
    )
}
export default function InsurancesTable(props: TProps) {
    const [t] = useTranslation();
    let headers: THeaders = {
        type: t(captions["lbl-type"]),
        limit: t(captions["lbl-limit"]),
        expDate: t(captions["lbl-exp-date"])
    }
    let defaultStyle = {
        marginLeft: '2%',
        lineHeight: '1',
        width: '100%'
    }
    let style = props.customStyle ? props.customStyle : defaultStyle;
    return (<React.Fragment>
        {props.tableData ? table(style, props, headers, t) : ''}
    </React.Fragment>);
}