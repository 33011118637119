import React, { memo } from 'react';
import {userInfo,Label,captions,rateTypes,useTranslation,ButtonMedium} from "../index";
const RateDetails = ( {props, data, rowData, onClickDismiss }) => {
    const [t] = useTranslation();
    const details: rateTypes.TAmounts = data;
    async function onClickAddNewRate() {
        const metaData = { amounts:data,rowData:rowData };
        props.history.push(`/editRate${userInfo.queryParams}`, metaData);
    }
    async function onClickAuditRate() {
        const metaData = { amounts:data,rowData:rowData };
        props.history.push(`/auditRate${userInfo.queryParams}`, metaData);
    }
    const amountSection = (details && details.length > 0 ? details.map((item: rateTypes.TAmount, index: number) => {
        let amount=null;
        try{
            amount=Number.parseFloat(item.amount).toFixed(2);
          }
        catch(err){
            amount=0.00;
        }
        return <div key={`amount-${index}`} className="row col-12" style={{ borderBottom: '1px solid antiquewhite' }}>
            <div className="row col-12">
                <Label size='xsm' label={item.amountTypeDescription}></Label>
            </div>
            <div className="row col-4">
                <Label size='md' label={`${amount}`}></Label>
            </div>
        </div>

    }) : <React.Fragment />);
    return (<React.Fragment>
        <div className="col-12" style={{ border: '1px solid antiquewhite', minHeight: '85%' }}>
            <div className="row pt-1">
            <div className="col-8">
                    <Label size='lg' label={rowData.productLineDescription}></Label>
                </div>
                <div className="col-2 pl-0 pr-0">
                    <Label size='md' style={'active' === rowData.status ? { backgroundColor: '#37E7A7' } : {}} label={rowData.status}></Label>
                </div>
                <div className="col-2 pr-0">
                    <ButtonMedium size='sm' style={{ minWidth: '100%',minHeight:'20px' }} label={t(captions['lbl-btn-dismiss'])} onClick={onClickDismiss} variant='outline-info'></ButtonMedium>
                </div>
            </div>
            <div className="row  col-12" style={{ borderBottom: '1px solid antiquewhite' }}>
                <Label size='lg' label={details && details.length > 0 ? details[0].pricingCategoryDescription : ''}></Label>
            </div>
            {amountSection}

        </div>
        <div className="row pt-1 pb-2">
            <div className="col-6 pl-3">
                <ButtonMedium label={t(captions['lbl-btn-edit-rate'])} labelStyle={{ paddingTop: '1%' }} style={{ width: '100%', maxHeight: '48px' }} onClick={(e) => { e.preventDefault(); onClickAddNewRate() }} variant='primary'></ButtonMedium>
            </div>
            <div className="col-6 pl-3 ">
                <ButtonMedium label={t(captions['lbl-btn-audit'])} labelStyle={{ paddingTop: '1%' }} style={{ width: '100%', maxHeight: '48px' }} onClick={(e) => { e.preventDefault(); onClickAuditRate() }} variant='outline-info'></ButtonMedium>
            </div>
        </div>
    </React.Fragment>
    );

}
export default memo(RateDetails);