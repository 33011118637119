import React from 'react'
import { useTranslation,captions as rateLabels,getFormattedDateFromString ,userInfo} from '../index';
import { captions as auditLabels, tableClasses as auditClasses } from '../../../../../modules/components/Audit/index'
import  moment from 'moment';

interface IAuditRow {
    oldValue: string,
    newValue: string,
    fieldId: string,
    auditTimeStamp: string,
    userName: string,
    dataType?: string
}
function RateAuditData(props) {
    const [t] = useTranslation();
    let style1 = {
        maxHeight: '72px', fontFamily: 'Apercu Pro', fontSize: 16, color: '#000000', border: '', paddingTop: ''
    }
    const formatDate = (date: string) => {
        try {
            if (userInfo.language) {
                let language = userInfo.language && userInfo.language.match("_") ? userInfo.language.replace("_", "-") : userInfo.language;
                return new Intl.DateTimeFormat(language).format(new Date(date));
            }
            else {
                return new Intl.DateTimeFormat().format(new Date(date));
            }
        }
        catch (err) {
            return date;
        }
    }
    let auditTableRows = (<tbody>
        {props.auditDataList ? props.auditDataList.map((item: IAuditRow, index: number) => {
            let style = style1; if (index === 0) {
                style = { ...style1, border: index === 0 ? 'none' : '' };
            }
            let label = '';
            // let oldValue=item.dataType.match('Date') && item.oldValue ? getFormattedDateFromString(item.oldValue) : item.oldValue;
            // // oldValue=oldValue &&?
            // let  newValue=item.dataType.match('Date') && item.newValue ? getFormattedDateFromString(item.newValue) : item.newValue;
            // let oldValue1=item.dataType.match('Date') && item.oldValue ? formatDate(item.oldValue) : item.oldValue;
            // // oldValue=oldValue &&?userInfo
            // let  newValue1=item.dataType.match('Date') && item.newValue ? formatDate(item.newValue) : item.newValue;
            
            try {
                label = t(auditLabels.audit[`lbl-field-name-${item.fieldId}`]);
            }
            catch (err) {
                label = item.fieldId;
            }
            return (
                <tr key={index} data-item={item} >
                    <td style={style}>{label}</td>
                    {/* <td style={style}>{item.oldValue}</td>
                    <td style={style}>{item.newValue}</td> */}
                    <td style={style}>{item.dataType.match('Date') && item.oldValue ? formatDate(item.oldValue) : item.oldValue}</td> 
                     <td style={style}>{item.dataType.match('Date') && item.newValue ? formatDate(item.newValue) : item.newValue}</td>
                    <td style={style}>{item.userName}</td>
                    <td style={style}>{item.auditTimeStamp ? formatDate(item.auditTimeStamp) : item.auditTimeStamp}</td>
                </tr>
            );
        }) : <React.Fragment />}
    </tbody>);
    const THead = () => {
        let headers: IAuditRow = {
            oldValue: t(auditLabels.audit["lbl-old-value"]),
            newValue: t(auditLabels.audit["lbl-new-value"]),
            fieldId: t(rateLabels["lbl-description"]),
            auditTimeStamp: t(auditLabels.audit["lbl-modification"]),
            userName: t(auditLabels.audit["lbl-modifed-by"])
        }
        const style1 = { paddingBottom: 2, fontFamily: 'Apercu Pro', fontSize: 12, border: 'none' }
        return (<thead><tr>
            <th style={style1} className={auditClasses['th-td']} >{headers.fieldId}</th>
            <th style={style1} className={auditClasses['th-td']}>{headers.oldValue}</th>
            <th style={style1} className={auditClasses['th-td']} >{headers.newValue}</th>
            <th style={style1} className={auditClasses['th-td']}>{headers.userName}</th>
            <th style={style1} className={auditClasses['th-td']}>{headers.auditTimeStamp}</th>
        </tr></thead>)
    }
    const table = () => {
        var tBody = auditTableRows;
        let tHead = <THead />;
        return (
            <table className="table" style={{ height: '40%', overflowY: 'scroll', width: '100%' }}>
                {tHead}
                {tBody}
            </table>
        )
    }
    return (
        <div className="row pt-3 pl-1" >
            {table()}
        </div>
    )
}

export default RateAuditData

