import React from 'react';
import classes from '../rate-search/RateSearch.module.scss';


import {captions,useTranslation,rateTypes,  getDateInUtcFromString, convertDateinDbExpectedFormat,DatePickerMedium,Input } from '../index';

type TProps = {
    tableData: rateTypes.TCodeDescriptions,
    tableClassName?: string,
    customStyle?: [],
     isEditCalled: | true | false,
    dateChangeHandler: Function,
    inputChangeHandler: Function,
    errors?: { description: string, amount: string, date: string, dateTableErrorMsg: string, amountTableErrorMsg: string, errorInTable: boolean | false | true }
    IsEditRate?:boolean
}
type THeaders = {
    amountTypeDescription: string,
    amount: string,
    effDate: string,
    amountTypeCode: string
}

const TBody = ({ props, t,headers }) => {
    const style1 = {
        maxHeight: '72px', fontFamily: 'Apercu Pro', fontSize: 16, color: '#000000', border: '', paddingTop: ''
    }
    var trs = props.tableData.map((item: rateTypes.TAmountType, index) => {
        let styles = style1; if (index === 0) {
            styles = { ...style1, border: index === 0 ? 'none' : '' };
        }
        let isEdit = props.isEditCalled;
        // let date =  getDateInUtcFromString(item.effectiveDate);
        let date = isEdit && item.effectiveDate ? getDateInUtcFromString(item.effectiveDate) : getDateInUtcFromString((new Date()).toString());
        //console.log("date in typef file is", date);
        let objectId = item.amountTypeCode;
        // console.log("object d is", objectId);
        // console.log("item d is", item);
      //  let flag= item.checkExpiration===1 || item.checkExpiration === '1' && !date;
        // let requiredEffDate = (<React.Fragment>
        //     { <span style={{ color: 'red', wordWrap: 'break-word' }}>{"err1"}</span> }
        // </React.Fragment>)
        // let requiredAmount = (<React.Fragment>
        //   {isEdit && !item.amount ?  <span style={{ color: 'red', wordWrap: 'break-word' }}>{"err2"}</span> : ''}
          
        // </React.Fragment>)
            // const regEx = /^[0-9\b]+$/;
            const regex = /^[0-9]*(?:\.\d{1,2})?$/; 
            let formatPolicyLimit = (<React.Fragment>
                {(isEdit && item.amount && (!regex.test(item.amount.toString()) )) ?
                    <span style={{ color: 'red', wordWrap: 'break-word' }}>{t(captions["err-numeric-amount"])}</span> : ''}
            </React.Fragment>)
            // let numericPolicyLimit = (<React.Fragment>
            //     {(isEdit && item.policyLimit && ( item.policyLimit > 10000000 || item.policyLimit <1)) ?
            //         <span style={{ color: 'red', wordWrap: 'break-word' }}>{t(captions["err-limit-numbers"])}</span> : ''}
            // </React.Fragment>)


        return <tr key={index} data-item={item} style={styles} >
            <td style={styles}>
                {item.amountTypeDescription}
            </td>
            {isEdit ?
                <React.Fragment>
                    <td style={{ ...styles, maxWidth: '100px' }} >
                        <Input objectId={objectId} value={item.amount} onChange={props.inputChangeHandler} ></Input>
                        
                        {/* {<div style={{ color: 'red' }} > {item.limitErrorMsg} </div>} */}
                        {/* {requiredAmount} */}
                        {/* {formatPolicyLimit}{numericPolicyLimit} */}
                        {formatPolicyLimit}
                    </td>
                    <td style={{ ...styles, maxWidth: '100px' }} >
                        <DatePickerMedium selectedDate={date} objectId={objectId} fieldLabel={headers.effDate}
                        // minDate={new Date()}
                        handleChange={props.dateChangeHandler} />
                        {/* {requiredEffDate} */}
                        {/* { <div style={{color:'red'}} > {item.dateErrorMsg} </div>} */}
                        {/* { props.errors.insurancecodetype==objectId && props.errors.datehandler && <div> {props.errors.dateerrormessage} </div>} */}
                    </td>
                    {/* <td style={styles}>
                        <ButtonMedium labelColor='red' label={t(captions["lbl-btn-delete"])} onClick={() => props.handleDelete(item)}
                            variant='link' style={{ maxHeight: '', marginLeft: '1%,min' }}></ButtonMedium>
                    </td> */}
                </React.Fragment>
                :
                <React.Fragment>
                    <td style={styles}>
                        {item.amount}
                    </td>
                    <td style={styles}>
                        {item.effectiveDate ? convertDateinDbExpectedFormat(new Date(item.effectiveDate)) : null}
                    </td>

                </React.Fragment>
            }
        </tr>
            ;
    });
    return (
        <tbody>
            {trs}
        </tbody>
    );
}
const THead = ({ headers }) => {
    const style1 = { paddingBottom: 2, fontFamily: 'Apercu Pro', fontSize: 12, border: 'none' }

    return (<thead><tr>
        <th style={style1} className={classes['th-td']} >{headers.amountTypeDescription}</th>
        <th style={style1} className={classes['th-td']} >{headers.amount}</th>
        <th style={style1} className={classes['th-td']} >{headers.effDate}</th>
    </tr></thead>)
}
const table = (customStyle, props, headers: THeaders, t) => {
    var tBody = props.tableData ? <TBody props={props} t={t} headers={headers} /> : '';
    let tHead = (props.tableData ? <THead headers={headers} /> : '')
    return (
        <div style={customStyle}>
            <table className={props.tableClassName ? props.tableClassName : "table"} >
                {tHead}
                {tBody}
            </table>
        </div>
    )
}

export default function RateTypeTable(props: TProps) {
    const [t] = useTranslation();
    let headers: THeaders = {
        amountTypeDescription: t(captions["lbl-description"]),
        amount: t(captions["lbl-amount"]),
        effDate: t(captions["lbl-effDate"]),
        amountTypeCode: "code"        
    }
    let defaultStyle = {
        marginLeft: '2%',
        lineHeight: '1',
        width: '100%'
    }
    let style = props.customStyle ? props.customStyle : defaultStyle;
    return (<React.Fragment>
        {props.tableData ? table(style, props, headers, t) : ''}
    </React.Fragment>);
}