import React from 'react';
import {cssRateSearch } from '../index';
import { getDateInUtcFromString,captions,useTranslation,DatePickerMedium,rateTypes,Input} from '../index';
const DisabledAmounts=[
    "PARTS","MILEAGEINAREA"]
type TProps = {
    tableData: rateTypes.TAmounts
    tableClassName?: string,
    customStyle?: {},
    dateChangeHandler: Function,
    inputChangeHandler: Function,
    errors?: { description: string, amount: string, date: string, dateTableErrorMsg: string, amountTableErrorMsg: string, errorInTable: boolean | false | true }
    isEditRate?: boolean,
    isFixedRate:boolean
}
type THeaders = {
    amountTypeDescription: string,
    previousAmount: string,
    currentAmount: string,
    effDate: string
}

const TBody = ({ props, t, headers }) => {
    const style1 = {
        maxHeight: '72px', fontFamily: 'Apercu Pro', fontSize: 16, color: '#000000', border: '', paddingTop: ''
    }
    var trs = props.tableData.map((item: rateTypes.TAmountTemp, index) => {
        let styles = style1; if (index === 0) {
            styles = { ...style1, border: index === 0 ? 'none' : '' };
        }
        let date = item.effectiveDate ? getDateInUtcFromString(item.effectiveDate) :'';
        let objectId = item.amountTypeCode;
        return <tr key={index} data-item={item} style={styles} >
            <td style={styles}>
                {item.amountTypeDescription}
            </td>
            <td style={styles}>
                {item.lastAmount}
            </td>
            <td style={{ ...styles, maxWidth: '100px' }} >
                <Input objectId={objectId}  isError={!item.isAmountValid}  errorTxt={t(captions["err-numeric-amount"])} value={item.amount} onChange={props.inputChangeHandler}  disabled={props.isFixedRate && DisabledAmounts.includes(item.amountTypeCode)}></Input>
                
            </td>
            <td style={{ ...styles, maxWidth: '100px' }} >
                <DatePickerMedium selectedDate={date} objectId={objectId} fieldLabel={headers.effDate} minDate={new Date()}
                    handleChange={props.dateChangeHandler}  disabled={props.isFixedRate && DisabledAmounts.includes(item.amountTypeCode)} />
            </td>

        </tr>

    });
    return (
        <tbody>
            {trs}
        </tbody>
    );
}
const THead = ({ headers }) => {
    let header: THeaders = headers;
    const style1 = { paddingBottom: 2, fontFamily: 'Apercu Pro', fontSize: 12, border: 'none' }

    return (<thead><tr>
        <th style={style1} className={cssRateSearch['th-td']} >{header.amountTypeDescription}</th>
        <th style={style1} className={cssRateSearch['th-td']} >{header.previousAmount}</th>
        <th style={style1} className={cssRateSearch['th-td']} >{header.currentAmount}</th>
        <th style={style1} className={cssRateSearch['th-td']} >{header.effDate}</th>
    </tr></thead>)
}
const table = (customStyle, props, headers: THeaders, t) => {
    var tBody = props.tableData ? <TBody props={props} t={t} headers={headers} /> : '';
    let tHead = (props.tableData ? <THead headers={headers} /> : '')
    return (
        <div style={customStyle}>
            <table className={props.tableClassName ? props.tableClassName : "table"} >
                {tHead}
                {tBody}
            </table>
        </div>
    )
}

export default function EditRateTable(props: TProps) {
    const [t] = useTranslation();
    let headers: THeaders = {
        amountTypeDescription: t(captions["lbl-description"]),
        previousAmount: t(captions["lbl-amount-previous"]),
        currentAmount: t(captions["lbl-amount-current"]),
        effDate: t(captions["lbl-effDate"]),
    }
    let defaultStyle = {
        marginLeft: '2%',
        lineHeight: '1',
        width: '100%'
    }
    let style = props.customStyle ? { ...defaultStyle, ...props.customStyle } : defaultStyle;
    return (<React.Fragment>
        {props.tableData ? table(style, props, headers, t) : ''}
    </React.Fragment>);
}