import React, { useState, useEffect } from 'react'
import {
    Post,loadAuditEntity,AppData,userInfo,ButtonMedium,Label,classes,ArrowRightIcon,arrowLeft,tableClasses,useTranslation,captions,convertDateinDbExpectedFormat, delay
} from './index';
interface IAudtiEntity {
    entityId: string
    objectId: string
    auditDate: string
    auditTimeStamp: string
    userName: string
    entityName: string
}
interface IDataList extends Array<IAudtiEntity> { }
interface IResponseCertListApi {
    Errors?: [],
    Result: | 'Success' | 'Failure'
    dataList?: IDataList
}
interface IData {
    entity: string, title: string, columnName: string, backButtonCaption: string, entityType: string
}
export default function Audit(props) {
    const [t] = useTranslation();
    const history = props.history;

    const [auditList, setAuditList] = useState([]);
    const [params] = useState(history && history.location && history.location.state ? history.location.state : {});
    const data: IData = params ? params : {};
    const tableFirstColumnHeader = t(captions.audit[`lbl-header-${data.columnName}`]);
    const title = `${t(captions.audit["lbl-title-audit"], { values: [tableFirstColumnHeader] })}`;
    const lowerCaseSuffix = `${t(captions.audit[`lbl-header-${data.columnName}-lowerCase`])}`;
    const labelBackButton = `${t(captions.audit[`lbl-btn-back-return`], { values: [lowerCaseSuffix] })}`;
    const formatDateStringByUserLocale = (inDate: string) => {
        let date = convertDateinDbExpectedFormat(new Date(inDate));
        return date ? date.toString() : '';
    }
    useEffect(() => {
        async function loadAuditList() {
            //delyed api call to let user object update finished
            await delay(200);
            return await Post(loadAuditEntity.subUri, {}, {
                serviceProviderId: AppData.serviceProviderId, serviceAdministratorId: AppData.serviceAdministratorId,
                entityType: data.entityType
            });
        }
        loadAuditList().then((data: IResponseCertListApi) => {
            data && data.dataList ? setAuditList(data.dataList) : setAuditList([]);
        });
    }, []);
    const TBody = () => {
        let style1 = {
            maxHeight: '72px', fontFamily: 'Apercu Pro', fontSize: 16, color: '#000000', border: '', paddingBottom: 1
        }
        return (
            (<tbody className='pl-2'>
                {auditList ? auditList.map((item: IAudtiEntity, index: number) => {
                    let style = style1; if (index === 0) {
                        style = { ...style1, border: index === 0 ? 'none' : '' };
                    }
                    return (
                        <tr key={index} data-item={item}  >
                            <td style={style}>{item.entityName}</td>
                            <td style={style}>{item.userName}</td>
                            <td style={style}>{formatDateStringByUserLocale(item.auditDate)}</td>
                            <td style={{ ...style, paddingTop: 0 }}>
                                <img
                                    alt="ArrowRightIcon"
                                    title="Click to see audit details"
                                    aria-hidden="true"
                                    src={ArrowRightIcon}
                                    onClick={() => { history.push(`/auditTable${userInfo.queryParams}`, { entityId: item.entityId, entityName: item.entityName, entityType: data.entityType, ...data }) }}
                                />
                            </td>
                        </tr>
                    );
                }) : <React.Fragment />}
            </tbody>)
        )
    }
    const THead = () => {
        const style1 = { paddingBottom: 2, fontFamily: 'Apercu Pro', fontSize: 12, border: 'none' }

        let headers = {
            filedname: tableFirstColumnHeader,
            modificationDate: t(captions.audit["lbl-last-modification"]),
            modifiedBy: t(captions.audit["lbl-last-modifed-by"])
        }
        return (<thead><tr>
            <th style={style1} className={tableClasses['th-td']} >{headers.filedname}</th>
            <th style={style1} className={tableClasses['th-td']}>{headers.modifiedBy}</th>
            <th style={style1} className={tableClasses['th-td']}>{headers.modificationDate}</th>
            <th style={style1} className={tableClasses['th-td']}></th>
        </tr></thead>)
    }
    const table = () => {
        var tBody = <TBody />;
        let tHead = <THead />;
        return (
            <table className="table" >
                {tHead}
                {tBody}
            </table>
        )
    }

    return (
        <div className={classes["audit"]}>
            <div className="row ">
                <div className='col-6 pl-0'>
                    <img style={{ paddingTop: '3%' }}
                        alt="ServiceBench"
                        title="ServiceBench (logo)"
                        aria-hidden="true"
                        src={arrowLeft}
                        onClick={() => { props.history.goBack() }}
                    />
                    <ButtonMedium style={{ minWidth: 0 }} captionSize="md" variant='link' label={labelBackButton} onClick={() => { props.history.goBack() }} />
                </div>
            </div>
            <div className="row" >
                <Label label={title} size="title" />

            </div>
            {/* <div className="row">
                <Label label={heading2} size="lg" />
            </div> */}
            <div className="row pt-2">
                {table()}
            </div>
        </div>
    )
}
