export const isEmpty = input => {
  if (typeof input === 'undefined' || input === 'null') {
    return true;
  }
  if (typeof input === 'function') {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  return !input || Object.keys(input).length === 0;
};

// export const isEquivalent = (a, b) => {
//   const aProps = Object.getOwnPropertyNames(a);
//   const bProps = Object.getOwnPropertyNames(b);

//   if (aProps.length !== bProps.length) {
//     return false;
//   }

//   for (let i = 0; i < aProps.length; i++) {
//     const propName = aProps[i];
//     if (a[propName] !== b[propName]) {
//       return false;
//     }
//   }
//   return true;
// };

// export const objectContainsValue = (obj, val) => {
//   let valuePresent = false;
//   if (obj)
//     for (const key of Object.keys(obj)) {
//       const value:(number|string) = obj[key];
//       if (typeof value === 'string' && value.toLowerCase().includes(val.toLowerCase())) {
//         if (
//           key === 'kitSequenceNumber' &&
//           ((val.length <= 2 && !value.endsWith(val)) || val.length > 2)
//         )
//           valuePresent = false;
//         else valuePresent = true;
//       } else if (typeof value === 'number' && toString(value).includes(val)) valuePresent = true;
//       else if (typeof value === 'object' && objectContainsValue(value, val)) valuePresent = true;
//     }
//   else valuePresent = false;
//   return valuePresent;
// };

// export const objectIncludesStringValue = (obj, val) =>
//   obj
//     .toString()
//     .trim()
//     .toLowerCase()
//     .includes(
//       val
//         .toString()
//         .trim()
//         .toLowerCase()
//     );

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(base64);
 };
 
export const getInitials = userkey => {
  if (isEmpty(userkey)) {
    return userkey;
  }
  const user=userkey.split('|').length>1 ? userkey.split('|')[1]: userkey;
  const names = user.toUpperCase().split(' ');
  let initials = names[0].substring(0,1);  
  if(names.length>1){
    initials += names[names.length-1].substring(0,1);
  }else{
    initials += names[0].substring(1,2);;
  }
  return initials;
}
