import { userInfo, AppData } from '../apis/apiUtitlity';
import { isEmpty, parseJwt } from '../utils/genericUtility';
// import { authTokenRefresh } from '../../constants/apiConst';

import { throwError } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

export const updateSessionId = () => (userInfo.sessionId = uuidv4());

let refreshRate = 20000;

let waitTime = 5 * 30 * 1000;

type TypeAuthResponse = {
  access_token?: string,
  refresh_token?: string,
  Error?: TypeError

}
type TypeError = {
  Code: string,
  Message: string
}

export const updateCurrentSession = (callback:Function) => {
  if (!isEmpty(userInfo.token)) {
    const jwt = parseJwt(userInfo.token);
      setSessionLogout(jwt.exp, callback);
      updateSessionId();      
    }  
}
export const checkTokens = () => {
  const token = userInfo.token;
  const refToken = userInfo.refreshToken;
  if (token) {
    if (isTokenValid(token)) {
      if (refToken) {
        // if (isTokenValid(refToken)) {
        if (isTokenExpired(token)) {
          userInfo.errorMessage = 'Token Has expired';
          userInfo.isSessionExpired = true;
          return false;

        } else {
          userInfo.isAuthorized = true;
          const jwt = parseJwt(userInfo.token);   
          const userDetails = jwt.username.split('|');
          userInfo.companyId = userDetails[0];
          userInfo.userName = userDetails[1];
          AppData.serviceAdministratorNumber = userInfo.companyId;
          return true;
        }
      }
      else {
        userInfo.errorMessage = 'Refresh token  not available';
        userInfo.isAuthorized = false;
        return false;
      }
    }
    else {
      userInfo.errorMessage = 'invalid token';
      return false;
    }
  }
  else {
    userInfo.errorMessage = 'Token not available ';
    userInfo.isAuthorized = false;
    return false;
  }
}
export const isTokenValid = (token: string) => {
  try {
    const jwt = parseJwt(token);
    if (jwt)
      return true;
  }
  catch (err) {
    console.log('token validation error', err);
    throwError(err);
    return false;
  }

}
export const isTokenExpired = (token: string) => {
  try {
    const at = parseJwt(token);
    if (at) {
      const exp = at.exp ? at.exp * 1000 : 0;
      // const exp =80000;
      const current = Date.now();
      if (current >= exp) {
        return true;
      }
    }

  }
  catch (err) {
    return true;
  }

}
export const setSessionLogout = (ts:any, callback:Function) => {
  const sessionExpiry: any|Date = new Date(ts * 1000);
  const difference: (number | bigint) = sessionExpiry - Date.now();
  if (difference < 0) waitTime = 0;
  const refreshToDoAt = difference - waitTime;
  // const refreshToDoAt = 20000;
  setTimeout(function () {
    // refreshCurrentSession(callback);
    callback();
  }, refreshToDoAt);
};

// const refreshCurrentSession = async (callback:Function) => {
//   let res:TypeAuthResponse ={};
//   try {
//     res = await Get(authTokenRefresh.subUri, {
//       'refresh-token': userInfo.refreshToken,
//       'x-api-key': userInfo.apiKey,
//       Accept: 'application/json',
//       Authorization: `Bearer ${userInfo.token}`
//     });
//     if (res.Error) {
//       setTimeout(() => {
//         callback();
//       }, waitTime - 1000);
//     } else {
//       userInfo.token = res.access_token;
//       userInfo.refreshToken = res.refresh_token;
//       updateCurrentSession(callback);
//     }
//   } catch (error) {
//     setTimeout(() => {
//       callback();
//     }, waitTime);
//   }
// };

export const updateRefreshRate = (currentRate: number) => {
  if (refreshRate !== currentRate) refreshRate = currentRate;
};

export const updateRefreshRateOnError = () => {
  refreshRate += 10000;
};

export const getRefreshRate = () => refreshRate;
