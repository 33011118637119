
export function convertObjectToLabelValueType(object: any, labelKey: string, valueKey: string) {
    if (object && object.length > 0) {
        let newObj: any = [];
        object.forEach(function (item: any, index) {
            let newItem = {
                label: item[labelKey],
                value: item[valueKey],
                data: item
            };
            newObj.push(newItem);
        });
        return newObj;
    }
    else { return {} }
}