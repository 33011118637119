import React, { useState, useEffect } from 'react'
import {
    Select,loadAuditRecords,Post,ButtonMedium,Label,arrowLeft,tableClasses,useTranslation,captions,convertDateinDbExpectedFormat,useMoment
} from './index';
interface IResponseCodeDescriptionListApi {
    Errors?: [],
    Result: | 'Success' | 'Failure'
    codeDescriptionList?: [],
    auditList?: []
}
interface IAuditRow {
    oldValue: string,
    newValue: string,
    fieldId: string,
    auditTimeStamp: string,
    userName: string,
    dataType?: string
}
interface IData {
    entity: string, title: string, columnName: string, backButtonCaption: string, name: string, entityId: string, entityType: string, entityName: string
}

export default function AuditTable(props) {
    const [selectedField, setSelectedField] = useState('');
    const [auditDataList, setAuditDatalist] = useState([]);
    // const [dateFrom, setDateFrom] = useState([]);
    const [t] = useTranslation();
    // const [getLocalisedDateByMoment,getFormattedDateFromString] = useLocalisedDateJs();
    const history = props.history;
    const [params] = useState(history && history.location && history.location.state ? history.location.state : {});
    const data: IData = params ? params : {};
    const fieldList = {
        "certification": [{
            "value": "expirationDate",
            "label": t(captions.audit["lbl-field-name-expirationDate"])
        },
        {
            "value": "certificationNumber",
            "label": t(captions.audit["lbl-field-name-certificationNumber"])
        }

        ],
        "insurance": [{
            "value": "policyExpirationDate",
            "label": t(captions.audit["lbl-field-name-policyExpirationDate"])
        },
        {
            "value": "coverageAmount",
            "label": t(captions.audit["lbl-field-name-coverageAmount"])
        }

        ]
    }
    const formatDateStringByUserLocale = (inDate: string) => {
        if (inDate && (inDate.match('AM') || inDate.match('PM'))) {
            return 'invalid date'
        }
        let date = convertDateinDbExpectedFormat(new Date(inDate));
        return date ? date.toString() : '';
    }
    // below code is going to give us the name of the entiry in lowe caser eg certification/insurance 
    const lowerCaseSuffix = `${t(captions.audit[`lbl-header-${data.columnName}-lowerCase`],)}`;
    // below coe is going to append suffix as audit in entity name eg certification audit /insuracne/audit 
    const captionReturnTo = t(captions.audit[`lbl-title-audit`], { values: [lowerCaseSuffix] });
    // belwo code is going to append prefix  in caption eg ' Return  to certification audit' /'Return  to insurance audit '
    const labelBackButton = `${t(captions.audit[`lbl-btn-back-return`], { values: [captionReturnTo] })}`;
    let [moment] = useMoment();

    let payLoad = {
        entityId: data.entityId,
        entityType: data.entityType,
        auditDateFrom: moment().subtract(365, "days").format('YYYY-MM-DD'),
        auditDateTo: moment().add(1, "days").format('YYYY-MM-DD')

    }
    async function loadAuditRecordForSelectField(selectedField: string, daysFrom: number) {
        // console.log('payload',payLoad);
        // await delay(200);
        if (daysFrom !== 0 && daysFrom > 0) {
            let auditDateFrom = moment().subtract(daysFrom, "days").format('YYYY-MM-DD');

            return await Post(loadAuditRecords.subUri, {}, selectedField ? { ...payLoad, fieldId: selectedField, auditDateFrom: auditDateFrom } : { ...payLoad, auditDateFrom: auditDateFrom });
        }
        else if (selectedField) {
            return await Post(loadAuditRecords.subUri, {}, selectedField ? { ...payLoad, fieldId: selectedField } : { ...payLoad });
        }
        else {
            return await Post(loadAuditRecords.subUri, {}, payLoad)
        }
    }
    useEffect(() => {
        loadAuditRecordForSelectField(null, null).then((data: IResponseCodeDescriptionListApi) => {
            // console.log('code list result', data);
            data && data.auditList ? setAuditDatalist(data.auditList) : setAuditDatalist([]);
        });
    }, []);
    async function onFieldNameChange(e) {
        if (e && e.target) {
            let value = e.target.value;
            // console.log('selectd field ', value)
            setSelectedField(value);
            loadAuditRecordForSelectField(value, null).then((data: IResponseCodeDescriptionListApi) => {
                console.log('code list result', data);
                data && data.auditList ? setAuditDatalist(data.auditList) : setAuditDatalist([]);
            });
        }
    }
    let style1 = {
        maxHeight: '72px', fontFamily: 'Apercu Pro', fontSize: 16, color: '#000000', border: '', paddingTop: ''
    }
    // async function onDaysSelectionHandler(days:number) {

    //     if (days===0 ||days>=0) {
    //         // console.log('selectd field ', value)
    //         // setSelectedField(value);            
    //         loadAuditRecordForSelectField(selectedField,days).then((data: IResponseCodeDescriptionListApi) => {
    //             console.log('code list result', data);
    //             data && data.auditList ? setAuditDatalist(data.auditList) : setAuditDatalist([]);
    //         });
    //     }
    // }
    let auditTableRows = (<tbody>
        {auditDataList ? auditDataList.map((item: IAuditRow, index: number) => {
            let style = style1; if (index === 0) {
                style = { ...style1, border: index === 0 ? 'none' : '' };
            }
            return (
                <tr key={index} data-item={item} >
                    <td style={style}>{t(captions.audit[`lbl-field-name-${item.fieldId}`])}</td>
                    {/* <td style={style}>{ item.oldValue}</td> */}
                    {/* <td style={style}>{ item.newValue}</td> */}
                    <td style={style}>{item.dataType.match('Date') && item.oldValue ? formatDateStringByUserLocale(item.oldValue) : item.oldValue}</td>
                    <td style={style}>{item.dataType.match('Date') && item.newValue ? formatDateStringByUserLocale(item.newValue) : item.newValue}</td>
                    <td style={style}>{item.userName}</td>
                    <td style={style}>{item.auditTimeStamp ? convertDateinDbExpectedFormat(new Date(item.auditTimeStamp)) : item.auditTimeStamp}</td>
                </tr>
            );
        }) : <React.Fragment />}
    </tbody>);
    const THead = () => {
        let headers: IAuditRow = {
            oldValue: t(captions.audit["lbl-old-value"]),
            newValue: t(captions.audit["lbl-new-value"]),
            fieldId: t(captions.audit["lbl-field-name"]),
            auditTimeStamp: t(captions.audit["lbl-modification"]),
            userName: t(captions.audit["lbl-modifed-by"])
        }
        const style1 = { paddingBottom: 2, fontFamily: 'Apercu Pro', fontSize: 12, border: 'none' }
        return (<thead><tr>
            <th style={style1} className={tableClasses['th-td']} >{headers.fieldId}</th>
            <th style={style1} className={tableClasses['th-td']}>{headers.oldValue}</th>
            <th style={style1} className={tableClasses['th-td']} >{headers.newValue}</th>
            <th style={style1} className={tableClasses['th-td']}>{headers.userName}</th>
            <th style={style1} className={tableClasses['th-td']}>{headers.auditTimeStamp}</th>
        </tr></thead>)
    }
    const table = () => {
        var tBody = auditTableRows;
        let tHead = <THead />;
        return (
            <table className="table" style={{ height: '40%', overflowY: 'scroll', width: '100%' }}>
                {tHead}
                {tBody}
            </table>
        )
    }
    return (
        <div >
            <div className="row" >
                <div className='col-6'>
                    <img style={{ paddingTop: '4%' }}
                        alt="ServiceBench"
                        title="ServiceBench (logo)"
                        aria-hidden="true"
                        src={arrowLeft}
                        onClick={() => { props.history.goBack() }}
                    />
                    <ButtonMedium style={{ minWidth: 0 }} captionSize="md" variant='link' label={labelBackButton} onClick={() => { props.history.goBack() }} />
                </div>
            </div>
            <div className="row pl-3 pt-0" >
                <Label label={data.entityName} size="title" style={{ marginTop: 0 }} />
            </div>
            <div className="row " >
                <div className="col-3 pr-1" style={{ paddingTop: '1%' }}>
                    <Select value={selectedField}
                        onChange={onFieldNameChange}
                        options={fieldList[`${data.columnName}`]}
                    />
                </div>
                <div className="col-5" />
                {/*                 
            <div className="col-4" >
            <ButtonMedium style={{ minWidth: '14%',paddingLeft:'0.5%',marginLeft:'1%' }} captionSize="md" variant='outline-info' label='All' onClick={() => { onDaysSelectionHandler(365)  }} /> 
            <ButtonMedium style={{ minWidth: '25%',paddingLeft:'0.5%',marginLeft:'1%' }} captionSize="md" variant='outline-info' label='Last 7 days' onClick={() => { onDaysSelectionHandler(7)  }} /> 
            
            <ButtonMedium style={{ minWidth: '25%',paddingLeft:'0.5%',marginLeft:'1%' }} captionSize="md" variant='outline-info' label='Last 90 days' onClick={() => { onDaysSelectionHandler(90)  }} /> 

            <ButtonMedium style={{ minWidth: '25%',paddingLeft:'0.5%',marginLeft:'1%' }} captionSize="md" variant='outline-info' label='Last  180 days' onClick={() => { onDaysSelectionHandler(180)  }} /> 

            </div> */}
            </div>
            <div className="row pt-3 pl-1" >
                {table()}
            </div>
        </div>
    )
}
