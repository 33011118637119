import React, { memo } from 'react';
import { useTable, useSortBy, usePagination, useRowState, useBlockLayout } from 'react-table';
import './Table.scss';

const Table = ({ data, columns, getRowProps, noDataText,fetchData, pageCount: controlledPageCount, showPagination, defaultPageSize }) => {
  const tableHeaders = React.useMemo(() => columns,columns);
  const contentData = data;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    // Pagination properties
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy }
  } = useTable(
    {
      columns: tableHeaders,
      data: contentData,
      initialState: { pageIndex: 0, pageSize: defaultPageSize },
      manualPagination: true,
      pageCount: controlledPageCount,
      autoResetPage: false,
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
    useRowState,
    useBlockLayout,
  );

  // React.useEffect(() => {
  //   /* console.log("in useEffect - pageIndex=" + pageIndex);
  //   console.log("in useEffect - pageSize=" + pageSize);
  //   console.log("in useEffect - sortBy=" + sortBy); */
  //   fetchData({ pageIndex, pageSize, sortBy })
  // }, [pageIndex, pageSize, sortBy])

  // Render the UI 
  return (
    <div className="table-content">
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className={
                    column.isSorted ? (column.isSortedDesc ? 'sort-desc' : 'sort-asc') : ''
                  }
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          { page.map(row => {
            prepareRow(row);            
            const rowColor = (row.index%2===0)?'-odd':'-even';
            const isFirstRow=(row.index===0)?'-first':''
            return (
              <tr {...row.getRowProps(getRowProps(row))} className={`${rowColor} ${isFirstRow}`}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
          {data.length === 0 && (
            <tr>
              <td colSpan={columns.length} className="no-data-text">
                {noDataText}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {showPagination && (
        <div className="pagination">
          <button
            type="button"
            disabled={!canPreviousPage}
            className="btn"
            onClick={() => previousPage()}
          >
            Previous
          </button>
          <div className="pagination-center">
            <span className="pagination-pageInfo">
              Page
              <div className="pagination-pageJump">
                <input
                  aria-label="jump to page"
                  type="number"
                  value={pageIndex + 1}                  
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                  }}
                />
              </div>{' '}
              of <span className="pagination-totalPages">{pageOptions.length}</span>
            </span>
            <span className="select-wrap">
              <select
                aria-label="rows per page"
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize} rows
                  </option>
                ))}
              </select>
            </span>
          </div>
          <button type="button" disabled={!canNextPage} className="btn" onClick={() => nextPage()}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(Table);
