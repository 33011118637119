import React, { memo } from 'react';
// import Table from '../../../../modules/components/Table/Table';
import Table from './Table';
import lock from '../images/lock.svg';
import chevronRight from '../images/chevron-right.svg';
import { useTranslation } from '../../../../../modules/utils';
import * as captions from '../captions/captionsRateMaintainance.json';
// import UserTooltip from './UserTooltip';
const getColumnWidth = (rows, accessor, headerText) => {
  const maxWidth = 400;
  const magicSpacing = 12;
  const cellLength = Math.max(
    ...rows.map(row => (`${row[accessor]}` || '').length),
    headerText.length,
  );
  return Math.min(maxWidth, cellLength * magicSpacing);
}

const RateListTable = ({  
  rateList,
  itemsPerPage,
  fetchData,
  rateItemCount,
  selectRateItem,
  widthMultiplier
}) => { 
  const [t]=useTranslation();
  const firstRow = (rateList && rateList.length>0)? [rateList[0]]: [];
  const totalPages = Math.ceil(parseFloat(rateItemCount) / parseFloat(itemsPerPage)); 
  const columns = [
    {
      Header: t(captions["lbl-product-line"]),
      accessor: 'productLineDescription', 
      minWidth: getColumnWidth(firstRow, 'productLineDescription', t(captions["lbl-product-line"])),
      width: Math.round(window.innerWidth * ((65*widthMultiplier)/100)),              
      // Cell: props =>
      //   props.value ? moment.utc(props.value).local().format('MM/DD/YYYY') : ''
    },
    {
      Header: t(captions["lbl-status"]),
      accessor: 'status',  
      minWidth: getColumnWidth(firstRow, 'status',  t(captions["lbl-status"])),
    width: Math.round(window.innerWidth * (20/100)) ,            
      Cell: props =>
       props.value ?('active'===props.value ?<span style={{backgroundColor:'#37E7A7'}}> {props.value}</span> :props.value): ''
    },
    
    {
      Header: '',
      accessor: 'locked', 
      minWidth: getColumnWidth(firstRow, 'locked', 'locked'),  
       width: Math.round(window.innerWidth * (8/100)),
      Cell: props =>
        props.value ? (<img src={lock} alt={lock} />) : (<img src={chevronRight}  alt={chevronRight}/>)   
    }
  ];
  return (
    <Table
      data={rateList}      
      columns={columns}       
      noDataText="No Data available."      
      showPagination={false}
      fetchData={fetchData}   
      pageCount={totalPages}  
      defaultPageSize={itemsPerPage}
      getRowProps={rowInfo => {
        return {
          onClick: e => {
            selectRateItem(e,rowInfo.original);
          }
        };
      }}     
    />
  );
};

export default memo(RateListTable);
